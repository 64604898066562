export const saveCatalogueDraft = ({
  selectedCompany,
  selectedWheel,
  selectedVehicleType,
  selectedVehicleSubType,
  selectedCarModel,
  specification,
  selectedLaunchYear,
  selectedDiscontinuedYear,
  bodyHeight,
  bodyLength,
  bodyWidth,
  selectedGen,
  selectedSeat,
  selectedColors,
  price,
  variantsArray,
  thumbnail,
  carExterior,
  carInterior,
  carTyres: props,
}: any) => {
  localStorage.setItem(
    "carDraft",
    JSON.stringify({
      selectedCompany,
      selectedWheel,
      selectedVehicleType,
      selectedVehicleSubType,
      selectedCarModel,
      specification,
      selectedLaunchYear,
      selectedDiscontinuedYear,
      bodyHeight,
      bodyLength,
      bodyWidth,
      selectedGen,
      selectedSeat,
      selectedColors,
      price,
      thumbnail,
      variantsArray,
      carExterior,
      carInterior,
      carTyres: props,
    })
  );
};

export const getCatalogueDraft = () => {
  return localStorage.getItem("carDraft");
};

export const clearCatalogueDraft = () => {
  return localStorage.removeItem("carDraft");
};
