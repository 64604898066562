import { ColumnDef } from "@tanstack/react-table";
import { IRequisiteProps } from "../../../interface";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { AppTable } from "../../../component";
import { FC } from "react";

export interface VariantTableProps {
  data: IRequisiteProps[];
  isLoading: boolean;
  onDelete: (prop: IRequisiteProps) => void;
}

export const VariantTable: FC<VariantTableProps> = ({
  data,
  isLoading,
  onDelete,
}) => {
  const columns: ColumnDef<IRequisiteProps>[] = [
    {
      id: "label",
      accessorKey: "label",
      header: "Variant Name",
      cell: ({ row }) => {
        return (
          <Typography textTransform="capitalize" variant="h5" fontWeight="500">
            {row.original.label}
          </Typography>
        );
      },
    },
    {
      id: "desc",
      accessorKey: "desc",
      header: "Description",
      cell: ({ row }) => {
        return (
          <Typography
            textTransform="capitalize"
            color="GrayText"
            fontWeight="500"
          >
            {row.original.desc ? row.original.desc : "Not available"}
          </Typography>
        );
      },
    },
    {
      id: "action",
      accessorKey: "desc",
      header: "Actions",
      meta: {
        align: "right",
      },
      cell: ({ row }) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            gap={3}
          >
            <IconButton>
              <EditTwoTone />
            </IconButton>
            <IconButton
              onClick={() => {
                onDelete(row.original);
              }}
            >
              {isLoading ? <CircularProgress /> : <DeleteTwoTone />}
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return <AppTable columns={columns} data={data} />;
};
