import { initializeApp } from "firebase/app";
import { deleteObject, getStorage } from "firebase/storage";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCTzxSvStrY5ZLGOq-bwsObtx2l6Hw4Ib0",
  authDomain: "crmtest-36060.firebaseapp.com",
  projectId: "crmtest-36060",
  storageBucket: "crmtest-36060.appspot.com",
  messagingSenderId: "838282316916",
  appId: "1:838282316916:web:224709babc74309cfb5521",
  measurementId: "G-EZW5XNTC0E",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

export const uploadImage = async (file: File) => {
  try {
    const name = file.name
      ? file.name
      : new Date().toUTCString().replace(" ", "");
    const storageRef = ref(storage, `files/catalogue/${name}`);
    const uploadTask = await uploadBytesResumable(storageRef, file);
    return await getDownloadURL(uploadTask.ref);
  } catch (err) {
    return err;
  }
};

export const deleteImage = async (fileRef: string) => {
  try {
    const storageRef = ref(storage, fileRef);
    await deleteObject(storageRef);
  } catch (err) {
    return err;
  }
};
