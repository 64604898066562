import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthSlice } from "../redux/features";

export const ProtectedRoute = () => {
  let auth = useAuthSlice();
  let location = useLocation();
  if (!auth.token) {
    return <Navigate to="/sign-in" state={{ from: location }} />;
  }
  return <Outlet />;
};
