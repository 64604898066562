import { IDatabaseProps } from "./api.interface";

type DurationType = "1_month" | "3_months" | "6_months" | "12_months";
export const durationOptions: DurationType[] = [
  "12_months",
  "1_month",
  "3_months",
  "6_months",
];

export interface IPackagesProps extends IDatabaseProps {
  packageName: string;
  duration: DurationType;
  stocksLength: number;
  carListedOnWebsite: number;
  remark?: string;
  features?: string[];
  hotPackage?: boolean;
  adminId?: string;
  price: number;
  status: boolean;
}
