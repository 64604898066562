import { useDispatch, useSelector } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authReducer, CatalogueReducer, LayoutReducer } from "./features";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  AuthApiMiddleware,
  AuthApiReducer,
  catalogueApiMiddleware,
  CatalogueApiReducer,
  DealerApiMiddleware,
  DealerApiReducer,
  PackageApiMiddleware,
  PackageApiReducer,
  RequisiteApiMiddleware,
  RequisiteApiReducer,
} from "./api";

const persistConfig = {
  key: "root",
  storage,
};

const AuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: combineReducers({
    // FEATURES
    auth: AuthReducer,
    layout: LayoutReducer,
    catalogue: CatalogueReducer,
    // API
    authApi: AuthApiReducer,
    requisiteApi: RequisiteApiReducer,
    catalogueApi: CatalogueApiReducer,
    packageApi: PackageApiReducer,
    dealerApi: DealerApiReducer,
  }),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false }).concat(
      AuthApiMiddleware,
      RequisiteApiMiddleware,
      catalogueApiMiddleware,
      PackageApiMiddleware,
      DealerApiMiddleware
    );
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
setupListeners(store.dispatch);
export let persistor = persistStore(store);
