import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "..";

export interface AuthSliceProps {
  showPassword: boolean;
  token: string | null;
  loggedIn: boolean;
}

const initialState: AuthSliceProps = {
  showPassword: false,
  token: null,
  loggedIn: false,
};

const AuthSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    handleShowPassword: (state, action: PayloadAction<boolean>) => {
      state.showPassword = action.payload;
    },
    saveToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      if (state.token) {
        state.loggedIn = true;
      }
    },
    removeToken: (state) => {
      state.token = null;
    },
    handleLoggedInEffect: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
  },
});

export const authReducer = AuthSlice.reducer;
export const useAuthSlice = () =>
  useAppSelector((state) => {
    return state.auth;
  });
export const {
  handleShowPassword,
  saveToken,
  removeToken,
  handleLoggedInEffect,
} = AuthSlice.actions;
