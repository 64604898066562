import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { IRequisiteProps } from "../../../interface";
import { Dispatch, FC, SetStateAction } from "react";
import { LoadingButton } from "@mui/lab";

export interface NewCarModelProps {
  onModel: boolean;
  setOnModel: Dispatch<SetStateAction<boolean>>;
  setFile: Dispatch<SetStateAction<File>>;
  onSubmit: (prop: IRequisiteProps) => void;
  fileUrl: string;
  uploading: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  isUpdateLoading?: boolean;
  isNewLoading: boolean;
  selected?: IRequisiteProps | null;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

export const NewCarModelComponent: FC<NewCarModelProps> = ({
  onModel,
  onSubmit,
  setOnModel,
  uploading,
  isNewLoading,
  isUpdateLoading,
  onClose,
  selected,
}) => {
  console.log(selected);
  return (
    <Modal
      open={onModel}
      sx={{
        backgroundColor: "rgba(0,0,0,0.1)",
      }}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack spacing={5} sx={style}>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          Creating Car Model
        </Typography>
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={{ label: selected?.label || "" }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched,
            errors,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                  defaultValue={values.label}
                  onChange={handleChange("label")}
                  onBlur={handleBlur("label")}
                  error={touched.label}
                  helperText={errors.label}
                  variant="outlined"
                  fullWidth
                  label="Enter car model title"
                />

                <Box display="flex" justifyContent="flex-end" gap={3}>
                  <Button
                    onClick={() => setOnModel(false)}
                    size="large"
                    variant="text"
                    disableElevation
                    type="button"
                    color="inherit"
                  >
                    Close
                  </Button>
                  <LoadingButton
                    disabled={!uploading || isNewLoading}
                    size="large"
                    variant="contained"
                    disableElevation
                    loading={isNewLoading}
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Stack>
    </Modal>
  );
};
