import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "..";
import moment from "moment";
import {
  ICatalogueProps,
  IColorsProps,
  IRequisiteProps,
} from "../../interface";
import { clearCatalogueDraft } from "../../utils";

export interface catalogueSliceProps {
  selectedCompany: CatalogueProps;
  selectedWheel: CatalogueProps;
  selectedVehicleType: CatalogueProps;
  selectedVehicleSubType: CatalogueProps;
  selectedCarModel: CatalogueProps;
  selectedLaunchYear: string;
  selectedDiscontinuedYear: string;
  selectedSeat: string;
  selectedGen: string;

  bodyHeight: string;
  bodyWidth: string;
  bodyLength: string;
  specification: string;
  price: string;
  years: Array<any>;

  variantsArray: VariantArrayProps[];
  selectedVariant: CatalogueProps;
  selectedFuelType: CatalogueProps;
  engineSize: string;
  transmission: string;
  variantPrice: string;
  selectedColors: IColorsProps[];
  foundDrafted: boolean;

  selectedCar: ICatalogueProps | null;
}

interface VariantArrayProps {
  fuelType: IRequisiteProps;
  variant: IRequisiteProps;
  price: string;
  engineSize: string;
  selectedColors: IColorsProps[];
  transmission: string;
}

const selectedInitialState: CatalogueProps = {
  label: "",
  id: "",
};

const initialState: catalogueSliceProps = {
  selectedCompany: selectedInitialState,
  selectedWheel: selectedInitialState,
  selectedVehicleType: selectedInitialState,
  selectedVehicleSubType: selectedInitialState,
  selectedCarModel: selectedInitialState,
  specification: "",
  selectedLaunchYear: "",
  selectedDiscontinuedYear: "",
  bodyHeight: "",
  bodyLength: "",
  selectedColors: [],
  bodyWidth: "",
  selectedGen: "",
  transmission: "",
  selectedSeat: "",
  years: [],
  price: "",
  foundDrafted: false,
  variantsArray: [],
  engineSize: "",
  selectedFuelType: selectedInitialState,
  selectedVariant: selectedInitialState,
  variantPrice: "",
  selectedCar: null,
};
interface CatalogueProps {
  label: string;
  id: string;
}

const catalogueSlice = createSlice({
  initialState,
  name: "catalogue",
  reducers: {
    setCompany: (state, action: PayloadAction<CatalogueProps>) => {
      state.selectedCompany = action.payload;
    },
    setWheel: (state, action: PayloadAction<CatalogueProps>) => {
      state.selectedWheel = action.payload;
    },
    setVehicleType: (state, action: PayloadAction<CatalogueProps>) => {
      state.selectedVehicleType = action.payload;
    },
    setVehicleSubType: (state, action: PayloadAction<CatalogueProps>) => {
      state.selectedVehicleSubType = action.payload;
    },
    setCarModel: (state, action: PayloadAction<CatalogueProps>) => {
      state.selectedCarModel = action.payload;
    },
    setSpecification: (state, action: PayloadAction<string>) => {
      state.specification = action.payload;
    },
    setYears: (state) => {
      const startYear = 1999; // Starting year
      const endYear = moment().year();
      for (let year = startYear; year <= endYear; year++) {
        state.years.push(moment().year(year).format("YYYY"));
      }
    },
    setLaunchYear: (state, action: PayloadAction<string>) => {
      state.selectedLaunchYear = action.payload;
    },
    setDiscontinuedYear: (state, action: PayloadAction<string>) => {
      state.selectedDiscontinuedYear = action.payload;
    },
    setBodyHeight: (state, action: PayloadAction<string>) => {
      state.bodyHeight = action.payload;
    },
    setBodyWidth: (state, action: PayloadAction<string>) => {
      state.bodyWidth = action.payload;
    },
    setBodyLength: (state, action: PayloadAction<string>) => {
      state.bodyLength = action.payload;
    },

    setSeat: (state, action: PayloadAction<string>) => {
      state.selectedSeat = action.payload;
    },
    setGeneration: (state, action: PayloadAction<string>) => {
      state.selectedGen = action.payload;
    },
    setColors: (state, action: PayloadAction<IColorsProps[]>) => {
      const existingColorsMap = new Map(
        state.selectedColors.map((color) => [color._id, color])
      );
      action.payload?.forEach((color) => {
        if (!existingColorsMap.has(color._id)) {
          existingColorsMap.set(color._id, color);
        }
      });
      state.selectedColors = Array.from(existingColorsMap.values());
    },
    setPrice: (state, action: PayloadAction<string>) => {
      state.price = action.payload;
    },
    setTransmission: (state, action: PayloadAction<string>) => {
      state.transmission = action.payload;
    },
    setDrafted: (state, action: PayloadAction<boolean>) => {
      state.foundDrafted = action.payload;
      if (action.payload === false) {
        state = initialState;
      }
    },
    setVariantArray: (state, action: PayloadAction<VariantArrayProps>) => {
      const payloadString = JSON.stringify(action.payload);
      const exists = state.variantsArray
        .map((item) => JSON.stringify(item))
        .includes(payloadString);
      if (!exists) {
        state.variantsArray.push(action.payload);
        state.variantPrice = "";
        state.selectedFuelType = selectedInitialState;
        state.engineSize = "";
        state.selectedVariant = selectedInitialState;
        state.transmission = "";
        state.selectedColors = [];
      }
    },
    removeVariant: (state, action: PayloadAction<number>) => {
      state.variantsArray.splice(action.payload, 1);
    },
    setVariant: (state, action: PayloadAction<CatalogueProps>) => {
      state.selectedVariant = action.payload;
    },
    setFuelType: (state, action: PayloadAction<CatalogueProps>) => {
      state.selectedFuelType = action.payload;
    },
    setVariantPrice: (state, action: PayloadAction<string>) => {
      state.variantPrice = action.payload;
    },
    setEngineSize: (state, action: PayloadAction<string>) => {
      state.engineSize = action.payload;
    },
    clearAllData: (state) => {
      state = initialState;
      clearCatalogueDraft();
    },
    setCarDetails: (state, action: PayloadAction<ICatalogueProps>) => {
      state.selectedCar = action.payload;
    },
  },
});

export const useCatalogueSlice = () =>
  useAppSelector((state) => {
    return state.catalogue;
  });
export const CatalogueReducer = catalogueSlice.reducer;
export const {
  setCompany,
  setWheel,
  setVehicleType,
  setVehicleSubType,
  setCarModel,
  setSpecification,
  setYears,
  setLaunchYear,
  setDiscontinuedYear,
  setBodyHeight,
  setBodyLength,
  setBodyWidth,
  setGeneration,
  setSeat,
  setColors,
  setPrice,
  setDrafted,
  setEngineSize,
  setFuelType,
  setVariant,
  setVariantArray,
  setVariantPrice,
  removeVariant,
  clearAllData,
  setCarDetails,
  setTransmission,
} = catalogueSlice.actions;
