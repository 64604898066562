import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useGetCatalogueQuery } from "../../redux/api";
import { useParams } from "react-router-dom";
import { IRequisiteProps } from "../../interface";
import { CarCard, PageTitle } from "../../component";

export const CarListPage = () => {
  const { brandId } = useParams();
  const { data } = useGetCatalogueQuery();
  const filter = data?.data.filter(
    (cars) => (cars.brand as IRequisiteProps)._id === brandId
  );
  return (
    <Box>
      {filter?.length !== 0 && (
        <PageTitle
          title={`${
            (
              data?.data.find(
                (cars) => (cars.brand as IRequisiteProps)._id === brandId
              )?.brand as IRequisiteProps
            )?.label
              ? (
                  data?.data.find(
                    (cars) => (cars.brand as IRequisiteProps)._id === brandId
                  )?.brand as IRequisiteProps
                )?.label
              : ""
          } Cars`}
        />
      )}

      <Stack spacing={3}>
        {filter?.map((props, i) => (
          <CarCard
            key={i}
            image={props?.thumbnail}
            brandImage={(props.brand as IRequisiteProps)?.image as string}
            brandName={(props.brand as IRequisiteProps)?.label}
            cardModel={(props.carModel as IRequisiteProps)?.label}
            launchYear={props.launchYear}
            price={props.lastRecordedPrice}
            redirect={`/car-details/${props?._id}`}
            variantLength={props.variants?.length}
            props={props}
          />
        ))}
      </Stack>
      {!filter?.length && (
        <Box>
          <Typography>No cars found for this brand</Typography>
          <Button onClick={() => window.history.back()} variant="contained">
            Go back
          </Button>
        </Box>
      )}
    </Box>
  );
};
