import styled from "@emotion/styled";
import {
  AccountCircleTwoTone,
  ClearAllTwoTone,
  LogoutTwoTone,
  NotificationsNoneTwoTone,
} from "@mui/icons-material";
import { Box, IconButton, Toolbar, Typography } from "@mui/material";
import { FC } from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useAppDispatch } from "../../redux";
import { removeToken } from "../../redux/features";
import { useNavigate } from "react-router-dom";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export interface NavBarProps {
  open: boolean;
  handleDrawerOpen: () => void;
  width: number;
  logoText: string;
}

export const NavBar: FC<NavBarProps> = ({
  handleDrawerOpen,
  open,
  width,
  logoText,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }: any) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: width,
      width: `calc(100% - ${width}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  return (
    <AppBar color="inherit" elevation={0} position="fixed" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            color="primary"
            aria-label="open menu"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <ClearAllTwoTone color="primary" />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {logoText}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton>
            <NotificationsNoneTwoTone fontSize="medium" />
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch(removeToken());
              navigate("/sign-in", { replace: true });
            }}
          >
            <AccountCircleTwoTone fontSize="medium" />
          </IconButton>
          <IconButton
            onClick={() => {
              dispatch(removeToken());
              navigate("/sign-in", { replace: true });
            }}
          >
            <LogoutTwoTone fontSize="medium" />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
