import { createApi } from "@reduxjs/toolkit/query/react";
import { useServer } from "../../utils";
import { IPackagesProps } from "../../interface";

const PackageApi = createApi({
  baseQuery: useServer,
  reducerPath: "packageApi",
  tagTypes: ["Package"],
  endpoints: (builder) => ({
    getAllPackages: builder.query<{ data: IPackagesProps[] }, void>({
      query: () => "/packages",
      providesTags: ["Package"],
    }),

    createPackage: builder.mutation<{ data: string }, IPackagesProps>({
      query: (newPackage) => ({
        url: "/packages",
        method: "POST",
        body: newPackage,
      }),
      invalidatesTags: ["Package"],
    }),

    updatePackage: builder.mutation<
      { data: string },
      Partial<{ id: string; payload: IPackagesProps }>
    >({
      query: ({ id, payload }) => ({
        url: `/packages/${id}`,
        method: "PUT",
        body: {
          ...payload,
        },
      }),
      invalidatesTags: ["Package"],
    }),

    deletePackage: builder.mutation<{ data: string }, string>({
      query: (id) => ({
        url: `/packages/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Package"],
    }),
  }),
});

export const PackageApiReducer = PackageApi.reducer;
export const PackageApiMiddleware = PackageApi.middleware;
export const {
  useCreatePackageMutation,
  useDeletePackageMutation,
  useGetAllPackagesQuery,
  useLazyGetAllPackagesQuery,
  useUpdatePackageMutation,
} = PackageApi;
