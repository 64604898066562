import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useCreateVariantMutation,
  useDeleteVariantMutation,
  useGetVariantQuery,
} from "../../../redux/api";
import { useAppDispatch } from "../../../redux";
import { handleToast } from "../../../redux/features";
import { IRequisiteProps } from "../../../interface";
import { PageTitle } from "../../../component";
import { VariantTable } from "./variant-table";
import { NewVariantModel } from "./new.variant";

export const VariantListPage = () => {
  const { data, isError, error, isLoading } = useGetVariantQuery();
  const [
    NewVehicleType,
    {
      isError: isNewError,
      error: newError,
      data: newData,
      isLoading: isNewLoading,
      isSuccess: isNewSuccess,
    },
  ] = useCreateVariantMutation();
  const [
    DeleteVehicleType,
    {
      isError: isDeleteError,
      error: deleteError,
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteVariantMutation();
  const [onModel, setOnModel] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isError) {
      console.log(error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isNewError) {
      console.log(newError);
    }
    if (isNewSuccess) {
      dispatch(handleToast({ show: true, message: newData?.data }));
    }
  }, [isNewError, newError, isNewSuccess, newData?.data, dispatch]);

  useEffect(() => {
    if (isDeleteError) {
      console.log(deleteError);
    }
    if (isDeleteSuccess) {
      dispatch(handleToast({ show: true, message: deleteData?.data }));
    }
  }, [isDeleteError, deleteError, isDeleteSuccess, deleteData?.data, dispatch]);

  useEffect(() => {
    if (isNewSuccess) {
      setOnModel(false);
    }
  }, [isNewSuccess, newData?.data]);

  const onSubmit = async ({ label, desc }: IRequisiteProps) => {
    await NewVehicleType({ label, desc });
  };

  const onDelete = async ({ _id, image }: IRequisiteProps) => {
    await DeleteVehicleType(_id as string);
  };

  if (isLoading) {
    return (
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    );
  } else
    return (
      <Box>
        <PageTitle
          addBtn={{
            action: () => setOnModel(true),
            label: "Variants",
          }}
          title="Make Vehicle Variants"
          subTitle="Manage variant sub type from here"
          breadcrumbs={[
            { path: "/", title: "Home", active: false },
            { path: "#", title: "Master", active: false },
            {
              path: "/variant",
              title: "variant",
              active: true,
            },
          ]}
        />
        {data?.data && (
          <VariantTable
            data={data.data}
            isLoading={isDeleteLoading}
            onDelete={onDelete}
          />
        )}

        {onModel && (
          <NewVariantModel
            isNewLoading={isNewLoading}
            onModel={onModel}
            onSubmit={onSubmit}
            setOnModel={setOnModel}
            onClose={() => {
              setOnModel(false);
            }}
          />
        )}
      </Box>
    );
};
