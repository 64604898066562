import {
  Box,
  Chip,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../component";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { durationOptions, IPackagesProps } from "../../../interface";
import { useCreatePackageMutation } from "../../../redux/api";
import { LoadingButton } from "@mui/lab";

export const NewPackagesPage = () => {
  const navigate = useNavigate();
  const { shadows } = useTheme();
  const [duration, setDuration] = useState<string>("");
  const [features, setFeatures] = useState<string[]>([
    "10 cars & stock can be managed",
    "24/7 support",
    "employees & branches management",
    "fully functional admin panel",
  ]);
  const [inputValue, setInputValue] = useState<string>("");
  const [NewPackage, { isError, error, data, isLoading, isSuccess }] =
    useCreatePackageMutation();

  useEffect(() => {
    if (isError) {
      console.log(error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      console.log(data?.data);
      navigate("/packages/list", { replace: true });
    }
  }, [isSuccess, data?.data, navigate]);

  const handleSubmit = async (props: IPackagesProps) => {
    console.log(props, features);
    await NewPackage({
      carListedOnWebsite: props.carListedOnWebsite,
      duration: duration as any,
      packageName: props.packageName,
      price: props.price,
      status: props.status,
      stocksLength: props.stocksLength,
      features: features,
      hotPackage: props.hotPackage,
    });
  };

  const handleDelete = (id: number) => {
    setFeatures(features.filter((_, index) => index !== id));
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      setFeatures([...features, inputValue.trim()]); // Add feature to state
      setInputValue(""); // Clear input field
    }
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const initialValues: IPackagesProps = {
    carListedOnWebsite: 10,
    duration: duration as any,
    packageName: "Basic Plan",
    price: 199,
    features: features,
    status: false,
    stocksLength: 10,
    hotPackage: false,
    remark: "",
  };
  return (
    <Box>
      <PageTitle
        addBtn={{
          action: () => navigate("/packages/list"),
          label: "List Packages",
        }}
        title="Make Packages For Dealers"
        subTitle="ADd dealer packages from here"
        breadcrumbs={[
          { path: "/", title: "Home", active: false },
          { path: "#", title: "Master", active: false },
          { path: "/packages/new", title: "Packages", active: true },
        ]}
      />
      <Box
        margin="auto"
        width={{ xl: "60%", lg: "60%" }}
        boxShadow={shadows[5]}
        p={3}
      >
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
          }) => (
            <form
              onSubmit={handleSubmit}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
            >
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  value={values.packageName}
                  onChange={handleChange("packageName")}
                  onBlur={handleBlur("packageName")}
                  error={touched.packageName}
                  helperText={errors.packageName}
                  label="Package Name"
                />
                <Box display="flex" gap={3} alignItems="center" my={3}>
                  <TextField
                    label="Cars length can be on website"
                    value={values.carListedOnWebsite}
                    onChange={handleChange("carListedOnWebsite")}
                    onBlur={handleBlur("carListedOnWebsite")}
                    error={touched.carListedOnWebsite}
                    helperText={errors.carListedOnWebsite}
                  />
                  <TextField
                    label="Stock Length Can be Uploaded"
                    value={values.stocksLength}
                    onChange={handleChange("stocksLength")}
                    onBlur={handleBlur("stocksLength")}
                    error={touched.stocksLength}
                    helperText={errors.stocksLength}
                  />
                  <TextField
                    label="Package Cost"
                    value={values.price}
                    onChange={handleChange("price")}
                    onBlur={handleBlur("price")}
                    error={touched.price}
                    helperText={errors.price}
                  />
                </Box>
                <Box display="flex" flexDirection="row" gap={1} flexWrap="wrap">
                  {features.map((feature, i) => (
                    <Chip
                      key={i}
                      label={feature}
                      variant="outlined"
                      onDelete={() => handleDelete(i)}
                    />
                  ))}
                </Box>
                <TextField
                  fullWidth
                  value={inputValue}
                  onChange={handleInputChange}
                  label="Type Feature"
                  onBlur={handleBlur("features")}
                  helperText="Write feature name & press enter"
                  onKeyPress={handleKeyPress}
                />
                <FormControl fullWidth>
                  <FormLabel>Select the Duration of package</FormLabel>
                  <Select
                    onChange={(prop) => {
                      console.log(prop.target.value);
                      setDuration(prop.target.value);
                      console.log(duration);
                    }}
                    value={duration}
                  >
                    <MenuItem value="" selected disabled>
                      none
                    </MenuItem>
                    {durationOptions.map((duration) => (
                      <MenuItem key={duration} value={duration}>
                        {duration}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap={3}
                    alignItems="center"
                  >
                    <Switch
                      value={values.hotPackage}
                      onChange={handleChange("hotPackage")}
                      onBlur={handleBlur("hotPackage")}
                    />
                    <FormLabel>Make Hot Package?</FormLabel>
                  </Box>
                </FormControl>
                <FormControl>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap={3}
                    alignItems="center"
                  >
                    <Switch
                      value={values.status}
                      onChange={handleChange("status")}
                      onBlur={handleBlur("status")}
                    />
                    <FormLabel>Upload As Active</FormLabel>
                  </Box>
                </FormControl>
                <LoadingButton
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                    }
                  }}
                  loading={isLoading}
                  variant="contained"
                  color="inherit"
                  type="submit"
                  disableElevation
                  size="large"
                >
                  Save Package
                </LoadingButton>
              </Stack>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
