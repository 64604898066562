import { createApi } from "@reduxjs/toolkit/query/react";
import { useServer } from "../../utils";
import { IColorsProps, IRequisiteProps } from "../../interface";

const RequisiteApi = createApi({
  baseQuery: useServer,
  reducerPath: "requisiteApi",
  tagTypes: ["requisiteApi"],
  endpoints: ({ mutation, query }) => ({
    // ! Wheelers
    GetWheels: query<{ data: IRequisiteProps[] }, void>({
      query: () => "/wheels",
      providesTags: ["requisiteApi"],
    }),
    CreateWheels: mutation<{ data: string }, IRequisiteProps>({
      query: ({ ...payload }) => {
        console.log(payload);
        return {
          url: "/wheels",
          method: "POST",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    UpdateWheels: mutation<{ data: string }, Partial<IRequisiteProps>>({
      query: ({ _id, ...rest }) => {
        return {
          url: `/wheels/${_id}`,
          body: {
            ...rest,
          },
          method: "PUT",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    DeleteWheels: mutation<{ data: string }, string>({
      query: (_id) => {
        return {
          url: `/wheels/${_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),

    // !Vehicle Type
    GetVehicleType: query<{ data: IRequisiteProps[] }, void>({
      query: () => "/vehicle-types",
      providesTags: ["requisiteApi"],
    }),
    CreateVehicleType: mutation<{ data: string }, IRequisiteProps>({
      query: ({ ...payload }) => {
        console.log(payload);
        return {
          url: "/vehicle-types",
          method: "POST",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    UpdateVehicleType: mutation<{ data: string }, Partial<IRequisiteProps>>({
      query: ({ _id, ...rest }) => {
        return {
          url: `/vehicle-types/${_id}`,
          body: {
            ...rest,
          },
          method: "PUT",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    DeleteVehicleType: mutation<{ data: string }, string>({
      query: (_id) => {
        return {
          url: `/vehicle-types/${_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),

    // ! Vehicle Sub Type
    GetVehicleSubType: query<{ data: IRequisiteProps[] }, void>({
      query: () => "/vehicle-sub-types",
      providesTags: ["requisiteApi"],
    }),
    CreateVehicleSubType: mutation<{ data: string }, IRequisiteProps>({
      query: ({ ...payload }) => {
        console.log(payload);
        return {
          url: "/vehicle-sub-types",
          method: "POST",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    UpdateVehicleSubType: mutation<{ data: string }, Partial<IRequisiteProps>>({
      query: ({ _id, ...rest }) => {
        return {
          url: `/vehicle-sub-types/${_id}`,
          body: {
            ...rest,
          },
          method: "PUT",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    DeleteVehicleSubType: mutation<{ data: string }, string>({
      query: (_id) => {
        return {
          url: `/vehicle-sub-types/${_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),

    // ! Company
    GetCompanies: query<{ data: IRequisiteProps[] }, void>({
      query: () => "/companies",
      providesTags: ["requisiteApi"],
    }),
    CreateCompanies: mutation<{ data: string }, IRequisiteProps>({
      query: ({ ...payload }) => {
        console.log(payload);
        return {
          url: "/companies",
          method: "POST",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    UpdateCompanies: mutation<{ data: string }, Partial<IRequisiteProps>>({
      query: ({ _id, ...rest }) => {
        return {
          url: `/companies/${_id}`,
          body: {
            ...rest,
          },
          method: "PUT",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    DeleteCompanies: mutation<{ data: string }, string>({
      query: (_id) => {
        return {
          url: `/companies/${_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),

    // ! Car Model
    GetCarModel: query<{ data: IRequisiteProps[] }, void>({
      query: () => "/models",
      providesTags: ["requisiteApi"],
    }),
    CreateCarModel: mutation<{ data: string }, IRequisiteProps>({
      query: ({ ...payload }) => {
        console.log(payload);
        return {
          url: "/models",
          method: "POST",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    UpdateCarModel: mutation<
      { data: string },
      Partial<{ payload: IRequisiteProps; id: string }>
    >({
      query: ({ id, payload }) => {
        console.log(id);
        return {
          url: `/models/${id}`,
          body: {
            ...payload,
          },
          method: "PUT",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    DeleteCarModel: mutation<{ data: string }, string>({
      query: (_id) => {
        return {
          url: `/models/${_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),

    // ! Fuel type
    GetFuelTypes: query<{ data: IRequisiteProps[] }, void>({
      query: () => "/fuel-type",
      providesTags: ["requisiteApi"],
    }),
    CreateFuelTypes: mutation<{ data: string }, IRequisiteProps>({
      query: ({ ...payload }) => {
        console.log(payload);
        return {
          url: "/fuel-type",
          method: "POST",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    UpdateFuelTypes: mutation<{ data: string }, Partial<IRequisiteProps>>({
      query: ({ _id, ...rest }) => {
        return {
          url: `/fuel-type/${_id}`,
          body: {
            ...rest,
          },
          method: "PUT",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    DeleteFuelTypes: mutation<{ data: string }, string>({
      query: (_id) => {
        return {
          url: `/fuel-type/${_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),

    // !Variant
    GetVariant: query<{ data: IRequisiteProps[] }, void>({
      query: () => "/variant",
      providesTags: ["requisiteApi"],
    }),
    CreateVariant: mutation<{ data: string }, IRequisiteProps>({
      query: ({ ...payload }) => {
        return {
          url: "/variant",
          method: "POST",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    UpdateVariant: mutation<{ data: string }, Partial<IRequisiteProps>>({
      query: ({ _id, ...payload }) => {
        return {
          url: `/variant/${_id}`,
          method: "PUT",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    DeleteVariant: mutation<{ data: string }, string>({
      query: (id) => {
        return {
          url: `/variant/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),

    // !Color
    GetColor: query<{ data: IColorsProps[] }, void>({
      query: () => "/color",
      providesTags: ["requisiteApi"],
    }),
    CreateColor: mutation<{ data: string }, IColorsProps>({
      query: ({ ...payload }) => {
        return {
          url: "/color",
          method: "POST",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    UpdateColor: mutation<{ data: string }, Partial<IColorsProps>>({
      query: ({ _id, ...payload }) => {
        return {
          url: `/color/${_id}`,
          method: "PUT",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
    DeleteColor: mutation<{ data: string }, string>({
      query: (id) => {
        return {
          url: `/color/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["requisiteApi"],
    }),
  }),
});

export const RequisiteApiReducer = RequisiteApi.reducer;
export const RequisiteApiMiddleware = RequisiteApi.middleware;
export const {
  useCreateWheelsMutation,
  useDeleteWheelsMutation,
  useGetWheelsQuery,
  useLazyGetWheelsQuery,
  useUpdateWheelsMutation,

  useCreateVehicleTypeMutation,
  useDeleteVehicleTypeMutation,
  useGetVehicleTypeQuery,
  useLazyGetVehicleTypeQuery,
  useUpdateVehicleTypeMutation,

  useCreateVehicleSubTypeMutation,
  useDeleteVehicleSubTypeMutation,
  useGetVehicleSubTypeQuery,
  useLazyGetVehicleSubTypeQuery,
  useUpdateVehicleSubTypeMutation,

  useCreateCompaniesMutation,
  useDeleteCompaniesMutation,
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useUpdateCompaniesMutation,

  useCreateCarModelMutation,
  useDeleteCarModelMutation,
  useGetCarModelQuery,
  useLazyGetCarModelQuery,
  useUpdateCarModelMutation,

  useCreateFuelTypesMutation,
  useDeleteFuelTypesMutation,
  useGetFuelTypesQuery,
  useLazyGetFuelTypesQuery,
  useUpdateFuelTypesMutation,

  useCreateVariantMutation,
  useDeleteVariantMutation,
  useGetVariantQuery,
  useLazyGetVariantQuery,
  useUpdateVariantMutation,

  useCreateColorMutation,
  useDeleteColorMutation,
  useGetColorQuery,
  useLazyGetColorQuery,
  useUpdateColorMutation,
} = RequisiteApi;
