import React, { FC } from "react";

import { Box, Breadcrumbs, Button, Link, Typography } from "@mui/material";
import { AddBoxTwoTone } from "@mui/icons-material";

export interface PageTitleProps {
  title: string;
  subTitle?: string;
  breadcrumbs?: {
    title: string;
    path: string;
    active: boolean;
  }[];
  addBtn?: {
    label: string;
    action: (prop?: string) => void;
  };
}

export const PageTitle: FC<PageTitleProps> = ({
  title,
  subTitle,
  breadcrumbs,
  addBtn,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 3,
      }}
    >
      <Box>
        <Typography variant="h4" textTransform="capitalize">
          {title}
        </Typography>
        {subTitle && (
          <Typography variant="subtitle1" fontWeight="500" color="grey">
            {subTitle}
          </Typography>
        )}
        {breadcrumbs?.length && (
          <Breadcrumbs maxItems={5} aria-label="breadcrumb">
            {breadcrumbs.map(({ path, title, active }, i) => (
              <Link
                key={i}
                underline="hover"
                color={active ? "primary" : "inherit"}
                href={path}
              >
                {title}
              </Link>
            ))}
          </Breadcrumbs>
        )}
      </Box>
      {addBtn && (
        <Button
          startIcon={<AddBoxTwoTone />}
          disableElevation
          variant="contained"
          onClick={() => addBtn.action()}
        >
          {addBtn.label}
        </Button>
      )}
    </Box>
  );
};
