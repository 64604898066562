import {
  Box,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { PageTitle } from "../../../component";
import { useNavigate } from "react-router-dom";
import { Field, Formik } from "formik";
import { IDealerProps } from "../../../interface";
import {
  useCreateDealerMutation,
  useGetAllPackagesQuery,
} from "../../../redux/api";
import { uploadImage } from "../../../utils";
import { useDropzone } from "react-dropzone";
import { CloudUploadTwoTone } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../redux";
import { handleToast } from "../../../redux/features";

export const NewDealerPage = () => {
  const [props, setProps] = useState<{
    dealerImage: string;
  }>({
    dealerImage: "",
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const [
    UploadDealer,
    {
      isError: isUploadError,
      error: uploadError,
      isLoading: isUploadLoading,
      isSuccess: isUploadSuccess,
      data: uploadData,
    },
  ] = useCreateDealerMutation();

  const { data, isError, error } = useGetAllPackagesQuery();

  useEffect(() => {
    if (isError) {
      console.log(error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isUploadError) {
      console.log(uploadError);
    }
  }, [isUploadError, uploadError]);

  useEffect(() => {
    if (isUploadSuccess) {
      dispatch(
        handleToast({ message: uploadData?.data as string, show: true })
      );
      navigate(-1);
    }
  }, [isUploadSuccess, uploadData?.data, dispatch, navigate]);

  const handleFileUpload = async (acceptedFiles: File[]) => {
    const imageUrls = await Promise.all(
      acceptedFiles.map(async (file) => {
        return await uploadImage(file);
      })
    );
    return imageUrls;
  };

  const onDealerImageDrop = useCallback(async (acceptedFiles: File[]) => {
    const [imageUrl] = await handleFileUpload(acceptedFiles);
    setProps((prevProps) => ({
      ...prevProps,
      dealerImage: imageUrl as string, // Explicitly typecast as string
    }));
  }, []);

  const dealerImageDrop = useDropzone({
    onDrop: (acceptedFiles) => onDealerImageDrop(acceptedFiles),
    accept: { "image/*": [] },
    maxFiles: 1,
  });

  const handleSubmit = async (props: IDealerProps) => {
    await UploadDealer(props);
  };
  return (
    <Box>
      <PageTitle
        addBtn={{
          action: () => navigate("/dealer/list"),
          label: "Dealer",
        }}
        title="Make Dealer"
        subTitle="Manage dealer from here"
        breadcrumbs={[
          { path: "/", title: "Home", active: false },
          { path: "#", title: "Master", active: false },
          { path: "/dealer/list", title: "Dealer", active: true },
        ]}
      />
      <Box width="70%" margin="auto">
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            email: "",
            name: "",
            password: "",
            photo: "",
            packageId: "",
            packageEnd: new Date(),
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            errors,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  value={values.name}
                  onChange={handleChange("name")}
                  onBlur={handleBlur("name")}
                  helperText={errors.name}
                  error={touched.name}
                  label="Enter dealer name"
                  fullWidth
                />
                <Box display="flex" gap={3}>
                  <TextField
                    value={values.email}
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    helperText={errors.name}
                    error={touched.name}
                    label="Enter Email address"
                    fullWidth
                  />
                  <TextField
                    type="password"
                    value={values.password}
                    onChange={handleChange("password")}
                    onBlur={handleBlur("password")}
                    helperText={errors.password}
                    error={touched.password}
                    label="Create password"
                    fullWidth
                  />
                </Box>
                <Field
                  as={Select}
                  value={values.packageId}
                  onChange={(prop: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue("packageId", prop.target.value);
                  }}
                  onBlur={handleBlur("packageId")}
                >
                  {data?.data.map(
                    ({
                      packageName,
                      _id,
                      carListedOnWebsite,
                      stocksLength,
                      price,
                    }) => (
                      <MenuItem value={_id} key={_id}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Typography>{packageName}</Typography>{" "}
                          <Typography>
                            {carListedOnWebsite} cars + {stocksLength} stocks{" "}
                            <span style={{ marginLeft: 10 }}>
                              {price.toLocaleString("en-IN", {
                                currency: "INR",
                                style: "currency",
                                notation: "standard",
                              })}
                            </span>
                          </Typography>
                        </Box>
                      </MenuItem>
                    )
                  )}
                </Field>
                <Box>
                  {!props.dealerImage && (
                    <>
                      <FormLabel>Upload Dealer Image</FormLabel>
                      <Box
                        border={1}
                        p={3}
                        borderColor={palette.primary.main}
                        {...dealerImageDrop.getRootProps()}
                        borderRadius={1}
                        sx={{ borderStyle: "dashed" }}
                      >
                        <input
                          multiple={false}
                          accept="image/*"
                          type="file"
                          {...dealerImageDrop.getInputProps()}
                        />
                        {dealerImageDrop.isDragActive ? (
                          <Box>
                            <CloudUploadTwoTone
                              color="primary"
                              fontSize="large"
                            />
                            <Typography color="grey">
                              Drop the thumbnail file here ...
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CloudUploadTwoTone
                              color="primary"
                              fontSize="large"
                            />
                            <Typography color="grey">
                              Drag 'n' drop some files here, or click to select
                              files
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </>
                  )}
                  <Box width={300}>
                    {props.dealerImage && (
                      <img
                        width="100%"
                        src={props.dealerImage}
                        alt="Thumbnail"
                      />
                    )}
                  </Box>
                </Box>
                <LoadingButton
                  type="submit"
                  loading={isUploadLoading}
                  variant="contained"
                  disableElevation
                >
                  Upload Dealer
                </LoadingButton>
              </Stack>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
