import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { RootState } from "../redux";

export const useServer = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND,
  prepareHeaders(headers, api) {
    headers.set(
      "Authorization",
      (api.getState() as unknown as RootState).auth.token as string
    );
  },
});
