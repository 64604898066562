import { createApi } from "@reduxjs/toolkit/query/react";
import { useServer } from "../../utils";
import { IDealerProps } from "../../interface";

export const dealerApi = createApi({
  reducerPath: "dealerApi",
  baseQuery: useServer,
  tagTypes: ["dealerApi"],
  endpoints: ({ query, mutation }) => ({
    getDealers: query<{ data: IDealerProps[] }, void>({
      query: () => "/dealer",
      providesTags: ["dealerApi"],
    }),
    createDealer: mutation<{ data: string }, IDealerProps>({
      query: (newDealer) => ({
        url: "/dealer/sign-up",
        method: "POST",
        body: newDealer,
      }),
      invalidatesTags: ["dealerApi"],
    }),
    updateDealer: mutation<
      { data: string },
      { id: string; payload: Partial<IDealerProps> }
    >({
      query: ({ id, payload }) => ({
        url: `/dealer/${id}`,
        method: "PUT",
        body: {
          ...payload,
        },
      }),
      invalidatesTags: ["dealerApi"],
    }),
    deleteDealer: mutation<{ data: string }, string>({
      query: (_id) => ({
        url: `/dealer/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["dealerApi"],
    }),
  }),
});

export const DealerApiReducer = dealerApi.reducer;
export const DealerApiMiddleware = dealerApi.middleware;
export const {
  useCreateDealerMutation,
  useDeleteDealerMutation,
  useGetDealersQuery,
  useLazyGetDealersQuery,
  useUpdateDealerMutation,
} = dealerApi;
