import {
  Box,
  Button,
  Chip,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  clearAllData,
  handleToast,
  removeVariant,
  setBodyHeight,
  setBodyLength,
  setBodyWidth,
  setCarModel,
  setColors,
  setCompany,
  setDiscontinuedYear,
  setDrafted,
  setEngineSize,
  setFuelType,
  setGeneration,
  setLaunchYear,
  setPrice,
  setSeat,
  setSpecification,
  setTransmission,
  setVariant,
  setVariantArray,
  setVariantPrice,
  setVehicleSubType,
  setVehicleType,
  setWheel,
  setYears,
  useCatalogueSlice,
} from "../../redux/features";
import {
  useCreateCatalogueMutation,
  useGetCarModelQuery,
  useGetColorQuery,
  useGetCompaniesQuery,
  useGetFuelTypesQuery,
  useGetVariantQuery,
  useGetVehicleSubTypeQuery,
  useGetVehicleTypeQuery,
  useGetWheelsQuery,
  useSignProfileQuery,
} from "../../redux/api";
import { useAppDispatch } from "../../redux";
import Grid2 from "@mui/material/Unstable_Grid2";
import "react-quill/dist/quill.snow.css";
import { useCallback, useEffect, useState } from "react";
import { ICatalogueProps, IColorsProps, VariantsProps } from "../../interface";
import {
  clearCatalogueDraft,
  getCatalogueDraft,
  saveCatalogueDraft,
  uploadImage,
} from "../../utils";
import { CloseTwoTone, CloudUploadTwoTone } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { batch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";

export const NewCataloguePage = () => {
  const [NewCatalogue, { isError, error, data, isSuccess, isLoading }] =
    useCreateCatalogueMutation();

  const {
    selectedCompany,
    selectedWheel,
    selectedVehicleType,
    selectedVehicleSubType,
    selectedCarModel,
    specification,
    years,
    selectedLaunchYear,
    selectedDiscontinuedYear,
    bodyHeight,
    bodyLength,
    bodyWidth,
    selectedGen,
    selectedSeat,
    price,
    foundDrafted,
    engineSize,
    selectedFuelType,
    selectedVariant,
    variantPrice,
    variantsArray,
    selectedColors,
    transmission,
  } = useCatalogueSlice();
  const draftedDetails = getCatalogueDraft();
  const dispatch = useAppDispatch();
  const [props, setProps] = useState<{
    thumbnailUrl: string;
    carExteriorUrl: string[];
    carInteriorUrl: string[];
    tyresUrl: string[];
  }>({
    thumbnailUrl: "",
    carExteriorUrl: [],
    carInteriorUrl: [],
    tyresUrl: [],
  });
  const { palette } = useTheme();
  const navigate = useNavigate();

  const { data: companies } = useGetCompaniesQuery();
  const { data: wheels } = useGetWheelsQuery();
  const { data: vehicleType } = useGetVehicleTypeQuery();
  const { data: vehicleSubType } = useGetVehicleSubTypeQuery();
  const { data: carModel } = useGetCarModelQuery();
  const { data: colors } = useGetColorQuery();
  const { data: variant } = useGetVariantQuery();
  const { data: fuelType } = useGetFuelTypesQuery();
  const { data: profile } = useSignProfileQuery();

  useEffect(() => {
    const filteredData = JSON.parse(draftedDetails as string);
    if (draftedDetails !== null) {
      batch(() => {
        dispatch(setDrafted(true));
        dispatch(setCompany(filteredData.selectedCompany));
        dispatch(setWheel(filteredData.selectedWheel));
        dispatch(setSpecification(filteredData.specification));
        dispatch(setVehicleType(filteredData.selectedVehicleType));
        dispatch(setVehicleSubType(filteredData.selectedVehicleSubType));
        dispatch(setCarModel(filteredData.selectedCarModel));
        dispatch(setColors(filteredData.selectedColors));
        dispatch(setSeat(filteredData.selectedSeat));
        dispatch(setLaunchYear(filteredData.selectedLaunchYear));
        dispatch(setDiscontinuedYear(filteredData.selectedDiscontinuedYear));
        dispatch(setGeneration(filteredData.selectedGen));
        dispatch(setPrice(filteredData.price));
        dispatch(setBodyLength(filteredData.bodyLength));
        dispatch(setBodyWidth(filteredData.bodyWidth));
        dispatch(setBodyHeight(filteredData.bodyHeight));
        if (filteredData?.variantsArray?.length) {
          filteredData.variantsArray.map((prop: any) => {
            return dispatch(setVariantArray(prop));
          });
        }
      });
    }
  }, [dispatch, draftedDetails]);

  const onCarExteriorDrop = useCallback(async (acceptedFiles: File[]) => {
    const images = await handleFileUpload(acceptedFiles);
    setProps((prevProps) => ({
      ...prevProps,
      carExteriorUrl: images as string[],
    }));
  }, []);

  const onCarInteriorDrop = useCallback(async (acceptedFiles: File[]) => {
    const images = await handleFileUpload(acceptedFiles);
    setProps((prevProps) => ({
      ...prevProps,
      carInteriorUrl: images as string[],
    }));
  }, []);

  const onCarTyresDrop = useCallback(async (acceptedFiles: File[]) => {
    const images = await handleFileUpload(acceptedFiles);
    setProps((prevProps) => ({
      ...prevProps,
      tyresUrl: images as string[],
    }));
  }, []);

  const carExteriorDrop = useDropzone({
    onDrop: onCarExteriorDrop,
    accept: { "image/*": [] },
  });

  const carInteriorDrop = useDropzone({
    onDrop: onCarInteriorDrop,
    accept: { "image/*": [] },
  });

  const carTyresDrop = useDropzone({
    onDrop: onCarTyresDrop,
    accept: { "image/*": [] },
  });

  const handleChange = (
    event: SelectChangeEvent<string[]>,
    colors: IColorsProps[]
  ) => {
    const {
      target: { value },
    } = event;

    const flattenValue: any = (value as any[]).flat();

    const selectedColorNames =
      typeof flattenValue === "string" ? flattenValue.split(",") : flattenValue;

    const selectedColorObjects = selectedColorNames
      .map((colorName: any) => colors.find((color) => color.name === colorName))
      .filter((color: any): color is IColorsProps => color !== undefined);

    return dispatch(setColors(selectedColorObjects));
  };

  const handleFileUpload = async (acceptedFiles: File[]) => {
    const imageUrls = await Promise.all(
      acceptedFiles.map(async (file) => {
        return await uploadImage(file);
      })
    );
    return imageUrls;
  };

  const onThumbnailDrop = useCallback(async (acceptedFiles: File[]) => {
    const [imageUrl] = await handleFileUpload(acceptedFiles);
    setProps((prevProps) => ({
      ...prevProps,
      thumbnailUrl: imageUrl as string, // Explicitly typecast as string
    }));
  }, []);

  const thumbnailDrop = useDropzone({
    onDrop: (acceptedFiles) => onThumbnailDrop(acceptedFiles),
    accept: { "image/*": [] },
    maxFiles: 1,
  });

  const givenEngineSize: number[] = [
    1.0, 1.2, 1.4, 1.5, 1.6, 1.8, 2.0, 2.2, 2.5, 3.0, 3.5, 4.0, 5.0, 6.2, 7.0,
  ];
  const vehicleTransmissionTypes: string[] = [
    "Automatic",
    "Manual",
    "CVT",
    "Dual-Clutch",
    "Semi-Automatic",
    "Tiptronic",
    "AMT",
  ];

  useEffect(() => {
    if (isError) {
      console.log(error as Error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearAllData());
      dispatch(handleToast({ show: true, message: data?.data }));
      dispatch(setDrafted(false));
      navigate("/", { replace: true });
    }
  }, [isSuccess, data?.data, dispatch, navigate]);

  const handleSubmit = async () => {
    if (profile?.data._id) {
      try {
        const parsed = JSON.parse(draftedDetails as string);

        console.log(parsed.variantsArray, variantsArray);
        if (!variantsArray || variantsArray.length === 0) {
          return dispatch(
            handleToast({ show: true, message: `Variants not found` })
          );
        } else {
          const finalVariant = variantsArray.map((data: any) => ({
            colors: data.selectedColors.map((color: any) => color._id),
            engineSize: data.engineSize,
            fuelType: data.fuelType?.id,
            price: data.price,
            variant: data.variant?.id,
            transmission: data.transmission,
          })) as VariantsProps[];

          const finalOutput: ICatalogueProps = {
            bodyHeight: parsed.bodyHeight,
            bodyWidth: parsed.bodyWidth,
            bodyLength: parsed.bodyLength,
            brand: parsed.selectedCompany?.id,
            carModel: parsed.selectedCarModel?.id,
            discontinuedYear: parsed.selectedDiscontinuedYear,
            generation: parsed.selectedGen,
            lastRecordedPrice: parsed.price,
            launchYear: parsed.selectedLaunchYear,
            specification: parsed.specification,
            thumbnail: parsed.thumbnail,
            variants: finalVariant, // Ensure variants is always passed
            vehicleSubType: parsed.selectedVehicleSubType?.id,
            vehicleType: parsed.selectedVehicleType?.id,
            wheels: parsed.selectedWheel?.id,
            adminId: profile?.data._id,
            carExterior: parsed.carExterior as string[],
            carInterior: parsed.carInterior as string[],
            carTyres: parsed.carTyres as string[],
          };
          await NewCatalogue(finalOutput);
        }
      } catch (error: any) {
        dispatch(
          handleToast({
            show: true,
            message: `Error submitting data: ${error.message}`,
          })
        );
      }
    }
  };

  return (
    <Box>
      <Box>
        {!foundDrafted ? (
          <Typography variant="h4">
            Select {!selectedCompany.id && "Brand"}{" "}
            {selectedCompany.id && !selectedWheel.id && "Wheels"}
            {selectedCompany.id &&
              selectedWheel.id &&
              !selectedVehicleType.id &&
              "Vehicle Type"}
            {selectedCompany.id &&
              selectedWheel.id &&
              selectedVehicleType.id &&
              !selectedVehicleSubType.id &&
              "Vehicle Sub Type"}
            {selectedCompany.id &&
              selectedWheel.id &&
              selectedVehicleType.id &&
              selectedVehicleSubType.id &&
              !selectedCarModel.id &&
              "Car Model"}
            {selectedCompany.id &&
              selectedWheel.id &&
              selectedVehicleType.id &&
              selectedVehicleSubType.id &&
              selectedCarModel.id &&
              "Enter Details"}
          </Typography>
        ) : (
          <Typography variant="h4">
            Your Make is Ready Start Making Variants
          </Typography>
        )}
        {selectedCompany.id.length !== 0 && (
          <Box mb={10}>
            <Typography variant="h5" fontWeight="500">
              Catalogue status{" "}
            </Typography>
            <Box display="flex" flexDirection="row" gap={3} flexWrap="wrap">
              <Typography textTransform="capitalize">
                {selectedCompany.label}
              </Typography>
              <Typography textTransform="capitalize">
                {selectedWheel.label}
              </Typography>
              <Typography textTransform="capitalize">
                {selectedVehicleType.label}
              </Typography>
              <Typography textTransform="capitalize">
                {selectedVehicleSubType.label}
              </Typography>
              <Typography textTransform="capitalize">
                {selectedCarModel.label}
              </Typography>
              <Typography textTransform="capitalize">
                {specification.length !== 0 && "specification"}
              </Typography>
              <Typography textTransform="capitalize">
                {selectedSeat.length !== 0 && `${selectedSeat} Seats`}
              </Typography>
              <Typography textTransform="capitalize">
                {selectedGen.length !== 0 && `${selectedGen} Generation`}
              </Typography>
              <Typography textTransform="capitalize">
                {bodyHeight.length > 0 && `${bodyHeight}(BH)`}
              </Typography>
              <Typography textTransform="capitalize">
                {bodyWidth.length > 0 && `${bodyWidth}(BW)`}
              </Typography>
              <Typography textTransform="capitalize">
                {bodyLength.length > 0 && `${bodyLength}(BL)`}
              </Typography>
              <Typography textTransform="capitalize">
                {selectedLaunchYear.length > 0 && `${selectedLaunchYear}(L.Y.)`}
              </Typography>
              <Typography textTransform="capitalize">
                {selectedDiscontinuedYear.length > 0 &&
                  `${selectedDiscontinuedYear}(D.Y.)`}
              </Typography>

              <Typography textTransform="capitalize">
                {price.length !== 0 && `Recorded Price ${price}`}
              </Typography>
            </Box>
          </Box>
        )}

        {foundDrafted && (
          <Box
            bgcolor={palette.primary.light}
            p={2}
            borderRadius={0.8}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Got drafted data
            <IconButton
              onClick={() => {
                clearCatalogueDraft();
                dispatch(setDrafted(false));
                dispatch(clearAllData());
              }}
              title="Clear"
            >
              <CloseTwoTone />
            </IconButton>
          </Box>
        )}
        {!foundDrafted ? (
          <>
            <Grid2 container spacing={3}>
              {!selectedCompany.id &&
                companies?.data.map(({ _id, label, image }, i) => (
                  <Grid2 xl={2} lg={2} key={_id}>
                    <Box
                      sx={{ cursor: "pointer", userSelect: "none" }}
                      bgcolor="#fff"
                      onClick={() => {
                        dispatch(
                          setCompany({
                            id: _id as string,
                            label,
                          })
                        );
                      }}
                      key={i}
                      p={1}
                      borderRadius={0.8}
                    >
                      <Box width="50%" margin="auto">
                        <img src={image} width="100%" alt={label} />
                      </Box>
                      <Typography textTransform="capitalize" fontWeight="500">
                        {label.replace(/-/g, " ")}
                      </Typography>
                    </Box>
                  </Grid2>
                ))}
            </Grid2>
            <Grid2 container spacing={3}>
              {!selectedWheel.id &&
                selectedCompany.id &&
                wheels?.data.map(({ _id, label, image }, i) => (
                  <Grid2 xl={2} lg={2} key={_id}>
                    <Box
                      sx={{ cursor: "pointer", userSelect: "none" }}
                      bgcolor="#fff"
                      onClick={() => {
                        dispatch(
                          setWheel({
                            id: _id as string,
                            label,
                          })
                        );
                      }}
                      key={i}
                      p={1}
                      borderRadius={0.8}
                    >
                      <img src={image} height={100} alt="" />
                      <Typography textTransform="capitalize" fontWeight="500">
                        {label.replace(/-/g, " ")}
                      </Typography>
                    </Box>
                  </Grid2>
                ))}
            </Grid2>
            <Grid2 container spacing={3}>
              {!selectedVehicleType.id &&
                selectedWheel.id &&
                selectedCompany.id &&
                vehicleType?.data.map(({ _id, label, image }, i) => (
                  <Grid2 xl={2} lg={2} key={_id}>
                    <Box
                      sx={{ cursor: "pointer", userSelect: "none" }}
                      bgcolor="#fff"
                      onClick={() => {
                        dispatch(
                          setVehicleType({
                            id: _id as string,
                            label,
                          })
                        );
                      }}
                      key={i}
                      p={1}
                      borderRadius={0.8}
                    >
                      <img src={image} height={100} alt="" />
                      <Typography textTransform="capitalize" fontWeight="500">
                        {label.replace(/-/g, " ")}
                      </Typography>
                    </Box>
                  </Grid2>
                ))}
            </Grid2>
            <Grid2 container spacing={3}>
              {!selectedVehicleSubType.id &&
                selectedVehicleType.id &&
                selectedWheel.id &&
                selectedCompany.id &&
                vehicleSubType?.data.map(({ _id, label, image }, i) => (
                  <Grid2 xl={2} lg={2} key={_id}>
                    <Box
                      sx={{ cursor: "pointer", userSelect: "none" }}
                      bgcolor="#fff"
                      onClick={() => {
                        dispatch(
                          setVehicleSubType({
                            id: _id as string,
                            label,
                          })
                        );
                      }}
                      key={i}
                      p={1}
                      borderRadius={0.8}
                    >
                      <img src={image} height={100} alt="" />
                      <Typography textTransform="capitalize" fontWeight="500">
                        {label.replace(/-/g, " ")}
                      </Typography>
                    </Box>
                  </Grid2>
                ))}
            </Grid2>

            <Grid2 container spacing={3}>
              {!selectedCarModel.id &&
                selectedVehicleSubType.id &&
                selectedVehicleType.id &&
                selectedWheel.id &&
                selectedCompany.id &&
                carModel?.data.map(({ _id, label, image }, i) => (
                  <Grid2 xl={2} lg={2} key={_id}>
                    <Box
                      sx={{ cursor: "pointer", userSelect: "none" }}
                      bgcolor="#fff"
                      onClick={() => {
                        dispatch(
                          setCarModel({
                            id: _id as string,
                            label,
                          })
                        );
                      }}
                      key={i}
                      p={1}
                      borderRadius={0.8}
                    >
                      <Typography textTransform="capitalize" fontWeight="500">
                        {label.replace(/-/g, " ")}
                      </Typography>
                    </Box>
                  </Grid2>
                ))}
            </Grid2>
            {selectedCompany.id &&
              selectedWheel.id &&
              selectedVehicleType.id &&
              selectedVehicleSubType.id &&
              selectedCarModel.id && (
                <Box>
                  <Box>
                    <Box display="flex" gap={3}>
                      <FormControl sx={{ flex: 1 }}>
                        <FormLabel>Select Generation</FormLabel>
                        <Select
                          value={selectedGen}
                          onChange={(prop) =>
                            dispatch(setGeneration(prop.target.value))
                          }
                        >
                          <MenuItem disabled selected>
                            None
                          </MenuItem>
                          <MenuItem value="1st">1st</MenuItem>
                          <MenuItem value="2nd">2nd</MenuItem>
                          <MenuItem value="3th">3rd</MenuItem>
                          <MenuItem value="4th">4th</MenuItem>
                          <MenuItem value="5th">5th</MenuItem>
                          <MenuItem value="6th">6th</MenuItem>
                          <MenuItem value="7th">7th</MenuItem>
                          <MenuItem value="8th">8th</MenuItem>
                          <MenuItem value="9th">9th</MenuItem>
                          <MenuItem value="10th">10th</MenuItem>
                          <MenuItem value="n/a">N/A</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" gap={3} mt={3}>
                    <TextField
                      value={bodyHeight}
                      onChange={(prop) => {
                        dispatch(setBodyHeight(prop.target.value));
                      }}
                      fullWidth
                      label="Body Height (mm)"
                      type="number"
                    />
                    <TextField
                      fullWidth
                      label="Body Width (mm)"
                      type="number"
                      value={bodyWidth}
                      onChange={(prop) => {
                        dispatch(setBodyWidth(prop.target.value));
                      }}
                    />
                    <TextField
                      fullWidth
                      label="Body Length (mm)"
                      type="number"
                      value={bodyLength}
                      onChange={(prop) => {
                        dispatch(setBodyLength(prop.target.value));
                      }}
                    />
                  </Box>
                  <Box mt={3}>
                    <Box display="flex" gap={3} mt={3}>
                      <FormControl fullWidth>
                        <FormLabel>Launch Year</FormLabel>
                        <Select
                          onOpen={() => {
                            dispatch(setYears());
                          }}
                          value={selectedLaunchYear}
                          onChange={(prop) => {
                            dispatch(setLaunchYear(prop.target.value));
                          }}
                        >
                          <MenuItem disabled selected>
                            None
                          </MenuItem>
                          <MenuItem value="dont'know">Don't Know</MenuItem>
                          {years.map((year) => (
                            <MenuItem value={year}>{year}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <FormLabel>Discontinued Year</FormLabel>
                        <Select
                          onOpen={() => {
                            dispatch(setYears());
                          }}
                          value={selectedDiscontinuedYear}
                          onChange={(prop) => {
                            dispatch(setDiscontinuedYear(prop.target.value));
                          }}
                        >
                          <MenuItem disabled selected>
                            None
                          </MenuItem>
                          <MenuItem value="dont'know">Don't Know</MenuItem>
                          {years.map((year) => (
                            <MenuItem value={year}>{year}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" gap={3} mt={3}>
                    <TextField
                      value={price}
                      onChange={(prop) =>
                        dispatch(setPrice(prop.target.value as string))
                      }
                      fullWidth
                      label="Last Recorded Price"
                    />
                  </Box>
                </Box>
              )}
            {selectedCompany.id &&
              selectedWheel.id &&
              selectedVehicleType.id &&
              selectedVehicleSubType.id &&
              selectedCarModel.id && (
                <FormControl sx={{ mt: 3 }} fullWidth>
                  <FormLabel>Write Specification</FormLabel>
                  <ReactQuill
                    value={specification}
                    onChange={(prop) => dispatch(setSpecification(prop))}
                    style={{
                      height: 300,
                    }}
                  />
                </FormControl>
              )}

            {selectedCompany.id &&
              selectedWheel.id &&
              selectedVehicleType.id &&
              selectedVehicleSubType.id &&
              selectedCarModel.id && (
                <Box mt={8}>
                  <Box>
                    {!props.thumbnailUrl && (
                      <>
                        <FormLabel>Upload Thumbnail</FormLabel>
                        <Box
                          border={1}
                          p={3}
                          borderColor={palette.primary.main}
                          {...thumbnailDrop.getRootProps()}
                          borderRadius={1}
                          sx={{ borderStyle: "dashed" }}
                        >
                          <input
                            multiple={false}
                            accept="image/*"
                            type="file"
                            {...thumbnailDrop.getInputProps()}
                          />
                          {thumbnailDrop.isDragActive ? (
                            <Box>
                              <CloudUploadTwoTone
                                color="primary"
                                fontSize="large"
                              />
                              <Typography color="grey">
                                Drop the thumbnail file here ...
                              </Typography>
                            </Box>
                          ) : (
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <CloudUploadTwoTone
                                color="primary"
                                fontSize="large"
                              />
                              <Typography color="grey">
                                Drag 'n' drop some files here, or click to
                                select files
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </>
                    )}
                    <Box width={300}>
                      {props.thumbnailUrl && (
                        <img
                          width="100%"
                          src={props.thumbnailUrl}
                          alt="Thumbnail"
                        />
                      )}
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <>
                      <FormLabel>Car Exterior</FormLabel>
                      <Box
                        border={1}
                        p={3}
                        borderColor={palette.primary.main}
                        {...carExteriorDrop.getRootProps()}
                        borderRadius={1}
                        sx={{ borderStyle: "dashed" }}
                      >
                        <input
                          multiple
                          accept="image/*"
                          type="file"
                          {...carExteriorDrop.getInputProps()}
                        />
                        {carExteriorDrop.isDragActive ? (
                          <Box>
                            <CloudUploadTwoTone
                              color="primary"
                              fontSize="large"
                            />
                            <Typography color="grey">
                              Drop the files here ...
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CloudUploadTwoTone
                              color="primary"
                              fontSize="large"
                            />
                            <Typography color="grey">
                              Drag 'n' drop some files here, or click to select
                              files
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </>
                    <Grid2 container spacing={3} mt={3}>
                      {props.carExteriorUrl.length > 0 &&
                        props.carExteriorUrl.map((image, i) => (
                          <Grid2 xl={3} lg={3} md={3} sm={12} xs={12}>
                            <img
                              src={image}
                              key={i}
                              alt={`Car Exterior ${i + 1}`}
                            />
                          </Grid2>
                        ))}
                    </Grid2>
                  </Box>
                  <Box mt={6}>
                    <>
                      <FormLabel>Car Interior</FormLabel>
                      <Box
                        border={1}
                        p={3}
                        borderColor={palette.primary.main}
                        {...carInteriorDrop.getRootProps()}
                        borderRadius={1}
                        sx={{ borderStyle: "dashed" }}
                      >
                        <input
                          accept="image/*"
                          multiple
                          type="file"
                          {...carInteriorDrop.getInputProps()}
                        />
                        {carInteriorDrop.isDragActive ? (
                          <Box>
                            <CloudUploadTwoTone
                              color="primary"
                              fontSize="large"
                            />
                            <Typography color="grey">
                              Drop the files here ...
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CloudUploadTwoTone
                              color="primary"
                              fontSize="large"
                            />
                            <Typography color="grey">
                              Drag 'n' drop some files here, or click to select
                              files
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </>
                    <Grid2 container mt={3}>
                      {props.carInteriorUrl.length > 0 &&
                        props.carInteriorUrl.map((image, i) => (
                          <Grid2 xl={3} lg={3} md={3} sm={12} xs={12}>
                            <img
                              src={image}
                              key={i}
                              alt={`Car Interior ${i + 1}`}
                            />
                          </Grid2>
                        ))}
                    </Grid2>
                  </Box>
                  <Box mt={3}>
                    <>
                      <FormLabel>Upload Tyres Images</FormLabel>
                      <Box
                        border={1}
                        p={3}
                        borderColor={palette.primary.main}
                        {...carTyresDrop.getRootProps()}
                        borderRadius={1}
                        sx={{ borderStyle: "dashed" }}
                      >
                        <input
                          accept="image/*"
                          multiple
                          type="file"
                          {...carTyresDrop.getInputProps()}
                        />
                        {carTyresDrop.isDragActive ? (
                          <Box>
                            <CloudUploadTwoTone
                              color="primary"
                              fontSize="large"
                            />
                            <Typography color="grey">
                              Drop the files here ...
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <CloudUploadTwoTone
                              color="primary"
                              fontSize="large"
                            />
                            <Typography color="grey">
                              Drag 'n' drop some files here, or click to select
                              files
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </>
                    <Grid2 container spacing={3} mt={3}>
                      {props.tyresUrl.length > 0 &&
                        props.tyresUrl.map((image, i) => (
                          <Grid2 xl={3} lg={3} md={3} sm={12} xs={12}>
                            <img src={image} key={i} alt={`Tyre ${i + 1}`} />
                          </Grid2>
                        ))}
                    </Grid2>
                  </Box>
                </Box>
              )}
            {selectedCompany.id &&
              selectedWheel.id &&
              selectedVehicleType.id &&
              selectedVehicleSubType.id &&
              selectedCarModel.id &&
              props.thumbnailUrl && (
                <Box
                  display="flex"
                  flexDirection="row"
                  mt={3}
                  justifyContent="flex-end"
                >
                  <Button
                    disableElevation
                    onClick={() => {
                      dispatch(setDrafted(true));
                      saveCatalogueDraft({
                        selectedCompany,
                        selectedWheel,
                        selectedVehicleType,
                        selectedVehicleSubType,
                        selectedCarModel,
                        specification,
                        selectedLaunchYear,
                        selectedDiscontinuedYear,
                        bodyHeight,
                        bodyLength,
                        bodyWidth,
                        selectedGen,
                        selectedSeat,
                        price,
                        thumbnail: props.thumbnailUrl,
                        carExterior: props.carExteriorUrl,
                        carInterior: props.carInteriorUrl,
                        carTyres: props.tyresUrl,
                      });
                    }}
                    type="button"
                    variant="contained"
                    color="inherit"
                  >
                    Next
                  </Button>
                </Box>
              )}
          </>
        ) : (
          <Box>
            <Box mb={3}>
              <Grid2 container spacing={3} mt={5}>
                {variantsArray?.map(
                  (
                    {
                      engineSize,
                      fuelType,
                      price,
                      variant: currentVariant,
                      selectedColors: currenColors,
                    },
                    i
                  ) => (
                    <Grid2 xl={6} lg={6} md={6} sm={12} xs={12}>
                      <Box key={i} border={2} p={2} borderRadius={0.8}>
                        <Typography
                          variant="h6"
                          fontWeight="500"
                          textTransform="capitalize"
                        >
                          {currentVariant?.label}
                        </Typography>
                        <Typography
                          variant="body1"
                          textTransform="capitalize"
                          color="GrayText"
                        >
                          {fuelType?.label}
                        </Typography>
                        <Typography
                          textTransform="capitalize"
                          variant="body2"
                          color="GrayText"
                        >
                          Engine Size : {engineSize}
                        </Typography>
                        <Typography textTransform="capitalize">
                          {price}
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap={3}
                          alignItems="center"
                          mt={3}
                        >
                          {currenColors?.map(
                            (
                              { hex, name }: { hex: string; name: string },
                              i: number
                            ) => (
                              <Box key={i}>
                                <Box
                                  border={1}
                                  height={30}
                                  width={50}
                                  bgcolor={hex}
                                />
                                <Typography textTransform="capitalize">
                                  {name}
                                </Typography>
                              </Box>
                            )
                          )}
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="end"
                        >
                          <IconButton
                            color="error"
                            onClick={() => dispatch(removeVariant(i))}
                          >
                            <CloseTwoTone />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid2>
                  )
                )}
              </Grid2>
            </Box>
            <Box flexDirection="row" gap={3} display="flex" alignItems="end">
              <FormControl fullWidth>
                <InputLabel id="color-multiple-chip-label">
                  Select Colors
                </InputLabel>
                <Select
                  labelId="color-multiple-chip-label"
                  id="color-multiple-chip"
                  multiple
                  value={selectedColors}
                  onChange={(event) =>
                    handleChange(
                      event as SelectChangeEvent<string[]>,
                      colors?.data as IColorsProps[]
                    )
                  }
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Colors" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {(selected as IColorsProps[]).map((color, i) => (
                        <Chip key={color._id} label={color.name} />
                      ))}
                    </Box>
                  )}
                >
                  {colors?.data.map(({ name, hex }) => (
                    <MenuItem key={name} value={name}>
                      <Box height={10} width={30} bgcolor={hex} mr={1} /> {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <FormLabel>Select Transmission</FormLabel>
                <Select
                  onChange={(prop) =>
                    dispatch(setTransmission(prop.target.value))
                  }
                  value={transmission}
                >
                  {vehicleTransmissionTypes.map((transmission, key) => (
                    <MenuItem key={key} value={transmission}>
                      {transmission}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <FormLabel>Select Engine Size</FormLabel>
                <Select
                  fullWidth
                  value={engineSize}
                  onChange={(prop) =>
                    dispatch(setEngineSize(prop.target.value))
                  }
                >
                  {givenEngineSize.map((size, i) => (
                    <MenuItem key={i} value={`${size} Ltr`}>
                      {size} Ltr.
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Price"
                value={variantPrice}
                onChange={(prop) =>
                  dispatch(setVariantPrice(prop.target.value))
                }
              />
            </Box>
            <Grid2 container mt={3}>
              <Grid2 xl={6} lg={6} md={6}>
                <Box>
                  <Typography variant="h5">Select Variant</Typography>
                  <Box
                    my={2}
                    display="flex"
                    flexWrap="wrap"
                    width="100%"
                    gap={3}
                  >
                    {variant?.data.map(({ label, _id }) => (
                      <Box
                        key={_id}
                        sx={{ cursor: "pointer", userSelect: "none" }}
                        bgcolor="#fff"
                        onClick={() => {
                          dispatch(
                            setVariant({
                              id: _id as string,
                              label,
                            })
                          );
                        }}
                        border={2}
                        borderColor={
                          selectedVariant.id === _id
                            ? palette.primary.main
                            : palette.grey[400]
                        }
                        p={1}
                        borderRadius={0.8}
                      >
                        <Typography textTransform="capitalize" fontWeight="500">
                          {label.replace(/-/g, " ")}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid2>
              <Grid2 xl={6} lg={6} md={6}>
                <Box>
                  <Typography variant="h5">Select Fuel Type</Typography>
                  <Box my={2} display="flex" flexWrap="wrap" gap={3}>
                    {fuelType?.data.map(({ label, _id, image }) => (
                      <Box
                        width={150}
                        key={_id}
                        sx={{ cursor: "pointer", userSelect: "none" }}
                        bgcolor="#fff"
                        onClick={() => {
                          dispatch(
                            setFuelType({
                              id: _id as string,
                              label,
                            })
                          );
                        }}
                        border={2}
                        borderColor={
                          selectedFuelType.id === _id
                            ? palette.primary.main
                            : palette.grey[400]
                        }
                        p={1}
                        borderRadius={0.8}
                      >
                        <img src={image} width="50%" alt={label} />
                        <Typography textTransform="capitalize" fontWeight="500">
                          {label.replace(/-/g, " ")}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Grid2>
            </Grid2>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="end"
              my={3}
              gap={3}
            >
              <LoadingButton
                type="button"
                loading={isLoading}
                onClick={handleSubmit}
              >
                Submit
              </LoadingButton>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  console.log("found variant", variantsArray);
                  saveCatalogueDraft({
                    selectedCompany,
                    selectedWheel,
                    selectedVehicleType,
                    selectedVehicleSubType,
                    selectedCarModel,
                    specification,
                    selectedLaunchYear,
                    selectedDiscontinuedYear,
                    bodyHeight,
                    bodyLength,
                    bodyWidth,
                    selectedGen,
                    selectedSeat,
                    price,
                    variantsArray:
                      variantsArray?.length !== 0 ? variantsArray : null,
                    thumbnail:
                      JSON.parse(getCatalogueDraft() as string).thumbnail ||
                      props.thumbnailUrl,
                    carExterior:
                      JSON.parse(getCatalogueDraft() as string).carExterior ||
                      props.carExteriorUrl,
                    carInterior:
                      JSON.parse(getCatalogueDraft() as string).carInterior ||
                      props.carInteriorUrl,
                    carTyres:
                      JSON.parse(getCatalogueDraft() as string).carTyres ||
                      props.tyresUrl,
                  });
                }}
              >
                Preview in draft
              </Button>
              <Button
                disabled={
                  selectedVariant.id === "" ||
                  selectedFuelType.id === "" ||
                  engineSize === "" ||
                  variantPrice === ""
                }
                onClick={() =>
                  dispatch(
                    setVariantArray({
                      engineSize: engineSize,
                      variant: selectedVariant,
                      fuelType: selectedFuelType,
                      price: variantPrice,
                      selectedColors: selectedColors,
                      transmission: transmission,
                    })
                  )
                }
                variant="contained"
                color="inherit"
                disableElevation
              >
                Save Variant {variantsArray.length + 1}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
