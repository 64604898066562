import React, { FC, useState } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import { MenuLinkProps } from "../../component";
import { NavBar } from "./navbar";
import { SideBar } from "./sidebar";
import { handleToast, useLayoutSlice } from "../../redux/features";
import { Button, IconButton, Snackbar } from "@mui/material";
import { useAppDispatch } from "../../redux";
import { CloseTwoTone } from "@mui/icons-material";

export interface DefaultLayoutProps {}

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const DefaultLayout: FC<DefaultLayoutProps> = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [collapse, setCollapse] = useState<boolean>(false);

  const { toast } = useLayoutSlice();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    setCollapse(!collapse);
  };

  const MakeModels: MenuLinkProps[] = [
    { label: "Wheels", open: true, path: "/wheels" },
    { label: "Vehicle Type", open: true, path: "/vehicle-type" },
    { label: "Vehicle Sub Type", open: true, path: "/vehicle-sub-type" },
    { label: "Company", open: true, path: "/company" },
    { label: "Car Models", open: true, path: "/car-models" },
    { label: "Fuel Type", open: true, path: "/fuel-type" },
    { label: "Variant", open: true, path: "/variant" },
    { label: "Color", open: true, path: "/colors" },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Snackbar
        open={toast.show}
        autoHideDuration={6000}
        onClose={() => dispatch(handleToast({ show: false, message: "" }))}
        message={toast.message}
        action={
          <React.Fragment>
            <Button
              color="secondary"
              size="small"
              onClick={() =>
                dispatch(handleToast({ show: false, message: "" }))
              }
            >
              CLOSE
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() =>
                dispatch(handleToast({ show: false, message: "" }))
              }
            >
              <CloseTwoTone fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <NavBar
        logoText="Car Dealers"
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        width={drawerWidth}
      />
      <SideBar
        MakeModels={MakeModels}
        collapse={collapse}
        handleClick={handleClick}
        handleDrawerClose={handleDrawerClose}
        open={open}
        width={drawerWidth}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3, }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};
