import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../component";
import {
  useCreateColorMutation,
  useDeleteColorMutation,
  useGetColorQuery,
} from "../../../redux/api";
import { IColorsProps } from "../../../interface";
import { NewColorModel } from "./new.colors";
import { useAppDispatch } from "../../../redux";
import { handleToast } from "../../../redux/features";
import { ColorTable } from "./table.colors";

export const ColorsListPage = () => {
  const [onModel, setOnModel] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { data, isError, error } = useGetColorQuery();
  const [
    NewColors,
    {
      isError: isNewError,
      error: newError,
      data: newData,
      isLoading: isNewLoading,
      isSuccess: isNewSuccess,
    },
  ] = useCreateColorMutation();
  const [
    DeleteColor,
    {
      isError: isDeleteError,
      error: deleteError,
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteColorMutation();

  useEffect(() => {
    if (isError) {
      console.log(error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isNewError) {
      console.log(newError);
    }
    if (isNewSuccess) {
      dispatch(handleToast({ show: true, message: newData?.data }));
    }
  }, [isNewError, newError, isNewSuccess, newData?.data, dispatch]);

  useEffect(() => {
    if (isDeleteError) {
      console.log(deleteError);
    }
    if (isDeleteSuccess) {
      dispatch(handleToast({ show: true, message: deleteData?.data }));
    }
  }, [isDeleteError, deleteError, isDeleteSuccess, deleteData?.data, dispatch]);

  useEffect(() => {
    if (isNewSuccess) {
      setOnModel(false);
    }
  }, [isNewSuccess, newData?.data]);

  const onSubmit = async ({ hex, name }: IColorsProps) => {
    await NewColors({ hex, name });
  };

  const onDelete = async ({ _id }: IColorsProps) => {
    await DeleteColor(_id as string);
  };

  return (
    <Box>
      <PageTitle
        addBtn={{
          action: () => setOnModel(true),
          label: "New Color",
        }}
        title="Defined Color List"
        subTitle="Manage colors from here"
        breadcrumbs={[
          { path: "/", title: "Home", active: false },
          { path: "#", title: "Master", active: false },
          { path: "/colors", title: "Colors", active: true },
          { path: "/colors", title: "List", active: true },
        ]}
      />
      {data?.data && (
        <ColorTable
          data={data.data}
          isLoading={isDeleteLoading}
          onDelete={onDelete}
        />
      )}
      {onModel && (
        <NewColorModel
          isNewLoading={isNewLoading}
          onModel={onModel}
          onSubmit={onSubmit}
          setOnModel={setOnModel}
          onClose={() => {
            setOnModel(false);
          }}
        />
      )}
    </Box>
  );
};
