import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { IRequisiteProps } from "../../../interface";
import { Dispatch, FC, SetStateAction } from "react";
import { CloudUploadTwoTone } from "@mui/icons-material";
import styled from "@emotion/styled";
import { useAppDispatch } from "../../../redux";
import { handleToast } from "../../../redux/features";
import { LoadingButton } from "@mui/lab";

export interface NewWheelModelProps {
  onModel: boolean;
  setOnModel: Dispatch<SetStateAction<boolean>>;
  setFile: Dispatch<SetStateAction<File>>;
  onSubmit: (prop: IRequisiteProps) => void;
  fileUrl: string;
  uploading: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;

  isNewLoading: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const NewWheelModel: FC<NewWheelModelProps> = ({
  onModel,
  onSubmit,
  setOnModel,
  fileUrl,
  uploading,
  isNewLoading,
  setFile,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  return (
    <Modal
      open={onModel}
      sx={{
        backgroundColor: "rgba(0,0,0,0.1)",
      }}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack spacing={5} sx={style}>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          Creating Wheels
        </Typography>
        <Formik
          onSubmit={onSubmit}
          initialValues={{ image: fileUrl, label: "", desc: "" }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched,
            errors,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                  defaultValue={values.label}
                  onChange={handleChange("label")}
                  onBlur={handleBlur("label")}
                  error={touched.label}
                  helperText={errors.label}
                  variant="outlined"
                  fullWidth
                  label="Enter Wheel title"
                />
                {fileUrl ? (
                  <Stack spacing={1}>
                    <Typography>File Uploaded Successfully</Typography>
                    <img
                      alt=""
                      style={{ borderRadius: 8 }}
                      width={100}
                      src={fileUrl}
                    />
                  </Stack>
                ) : (
                  <Button
                    disableElevation
                    component="label"
                    role={undefined}
                    disabled={!uploading}
                    size="large"
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUploadTwoTone />}
                  >
                    {uploading ? "Upload Image" : "Images Uploading..."}
                    <VisuallyHiddenInput
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target.files) {
                          setFile(e.target?.files[0] as File);
                        } else {
                          dispatch(
                            handleToast({
                              show: true,
                              message: "please select image",
                            })
                          );
                        }
                      }}
                      type="file"
                    />
                  </Button>
                )}
                <TextField
                  defaultValue={values.desc}
                  onChange={handleChange("desc")}
                  onBlur={handleBlur("desc")}
                  error={touched.desc}
                  helperText={errors.desc}
                  multiline
                  rows={3}
                  variant="outlined"
                  fullWidth
                  label="Enter Wheel title"
                />
                <Box display="flex" justifyContent="flex-end" gap={3}>
                  <Button
                    onClick={() => setOnModel(false)}
                    size="large"
                    variant="text"
                    disableElevation
                    type="button"
                    color="inherit"
                  >
                    Close
                  </Button>
                  <LoadingButton
                    disabled={!uploading || isNewLoading}
                    size="large"
                    variant="contained"
                    disableElevation
                    loading={isNewLoading}
                    type="submit"
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Stack>
    </Modal>
  );
};
