import { createApi } from "@reduxjs/toolkit/query/react";
import { useServer } from "../../utils";
import { ICatalogueProps } from "../../interface";

const CatalogueApi = createApi({
  baseQuery: useServer,
  reducerPath: "catalogueApi",
  tagTypes: ["catalogueApi"],
  endpoints: ({ mutation, query }) => ({
    GetCatalogue: query<{ data: ICatalogueProps[] }, void>({
      query: () => "/catalogue",
      providesTags: ["catalogueApi"],
    }),
    CreateCatalogue: mutation<{ data: string }, ICatalogueProps>({
      query: ({ ...payload }) => {
        console.log("mainPayload", payload);
        return {
          url: "/catalogue",
          method: "POST",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["catalogueApi"],
    }),
    UpdateCatalogue: mutation<{ data: string }, Partial<ICatalogueProps>>({
      query: ({ _id, ...payload }) => {
        return {
          url: `/catalogue/${_id}`,
          method: "PUT",
          body: {
            ...payload,
          },
        };
      },
      invalidatesTags: ["catalogueApi"],
    }),
    DeleteCatalogue: mutation<{ data: string }, string>({
      query: (_id) => {
        return {
          url: `/catalogue/${_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["catalogueApi"],
    }),
  }),
});

export const CatalogueApiReducer = CatalogueApi.reducer;
export const catalogueApiMiddleware = CatalogueApi.middleware;
export const {
  useCreateCatalogueMutation,
  useDeleteCatalogueMutation,
  useGetCatalogueQuery,
  useLazyGetCatalogueQuery,
  useUpdateCatalogueMutation,
} = CatalogueApi;
