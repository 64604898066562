import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  useTheme,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";

export interface MenuLinkProps {
  open: boolean;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  label: string;
  path: string;
}

export const MenuLink: FC<MenuLinkProps> = ({ Icon, open, label, path }) => {
  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname, end: true });
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <ListItem
      onClick={() => navigate(path)}
      disablePadding
      sx={{
        display: "block",
        color: match ? palette.primary.main : palette.grey[600],
        backgroundColor: match ? palette.primary.light : "transparent",
        borderRadius: 1,
      }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        {Icon && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <Icon color={match ? "primary" : "inherit"} />
          </ListItemIcon>
        )}
        <ListItemText
          sx={{
            opacity: open ? 1 : 0,
            fontSize: "0.875rem",
            textTransform: "capitalize",
            fontSizeAdjust: 30,
          }}
        >
          {label}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
};
