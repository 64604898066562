import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PageTitle } from "../../../component";
import { CircularProgress } from "@mui/material";
import {
  useCreateCarModelMutation,
  useDeleteCarModelMutation,
  useGetCarModelQuery,
  useUpdateCarModelMutation,
} from "../../../redux/api";
import { IRequisiteProps } from "../../../interface";
import { useAppDispatch } from "../../../redux";
import { handleToast } from "../../../redux/features";
import { deleteImage, uploadImage } from "../../../utils";
import { CarModelTable } from "./table.company";
import { NewCarModelComponent } from "./new.company";

export const CarModelListPage = () => {
  const { data, isError, error, isLoading } = useGetCarModelQuery();
  const [
    NewCarModel,
    {
      isError: isNewError,
      error: newError,
      data: newData,
      isLoading: isNewLoading,
      isSuccess: isNewSuccess,
    },
  ] = useCreateCarModelMutation();
  const [
    DeleteCarModel,
    {
      isError: isDeleteError,
      error: deleteError,
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteCarModelMutation();
  const [
    UpdateCarModel,
    {
      isError: isUpdateError,
      error: updateError,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      data: updateData,
    },
  ] = useUpdateCarModelMutation();

  const [onModel, setOnModel] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string | null>();
  const [selected, setSelected] = useState<IRequisiteProps | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isError) {
      console.log(error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isUpdateError) {
      console.log(updateError);
    }
  }, [isUpdateError, updateError]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(handleToast({ show: true, message: updateData?.data }));
      setOnModel(false);
      setFile(null);
      setUploading(false);
      setFileUrl(null);
      setSelected(null);
    }
  }, [isUpdateSuccess, updateData?.data, dispatch]);

  useEffect(() => {
    if (isNewError) {
      console.log(newError);
    }
    if (isNewSuccess) {
      dispatch(handleToast({ show: true, message: newData?.data }));
    }
  }, [isNewError, newError, isNewSuccess, newData?.data, dispatch]);

  useEffect(() => {
    if (isDeleteError) {
      console.log(deleteError);
    }
    if (isDeleteSuccess) {
      dispatch(handleToast({ show: true, message: deleteData?.data }));
    }
  }, [isDeleteError, deleteError, isDeleteSuccess, deleteData?.data, dispatch]);

  useEffect(() => {
    setUploading(true);

    if (file) {
      setUploading(true);
      (async () => {
        setUploading(false);
        const fileLink = await uploadImage(file);
        setFileUrl(fileLink as string);
      })();
    } else {
      dispatch(handleToast({ show: true, message: "please select image" }));
    }
    if (fileUrl) {
      setUploading(true);
    }
  }, [file, dispatch, fileUrl]);

  useEffect(() => {
    if (isNewSuccess) {
      setOnModel(false);
      setFile(null);
      setUploading(false);
      setFileUrl(null);
    }
  }, [isNewSuccess, newData?.data]);

  const onSubmit = async ({ label, desc, _id }: IRequisiteProps) => {
    if (selected) {
      await UpdateCarModel({
        id: selected._id,
        payload: {
          label,
          desc,
        },
      });
    } else {
      await NewCarModel({ label, desc });
    }
  };

  const onDelete = async ({ _id, image }: IRequisiteProps) => {
    await deleteImage(image as string);
    await DeleteCarModel(_id as string);
  };

  if (isLoading) {
    return (
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    );
  } else
    return (
      <div>
        <PageTitle
          addBtn={{
            action: () => setOnModel(true),
            label: "New Car Model",
          }}
          title="Make Car Model"
          subTitle="Manage car model from here"
          breadcrumbs={[
            { path: "/", title: "Home", active: false },
            { path: "#", title: "Master", active: false },
            { path: "/car-model", title: "car model", active: true },
          ]}
        />
        {data?.data && (
          <CarModelTable
            data={data.data}
            isLoading={isDeleteLoading}
            onDelete={onDelete}
            onUpdate={(prop) => {
              setOnModel(true);
              setSelected(prop);
            }}
          />
        )}
        {onModel && (
          <NewCarModelComponent
            selected={selected}
            fileUrl={fileUrl as string}
            isNewLoading={isNewLoading}
            onModel={onModel}
            onSubmit={onSubmit}
            setFile={setFile as Dispatch<SetStateAction<File>>}
            setOnModel={setOnModel}
            onClose={() => {
              setOnModel(false);
              setFile(null);
              setFileUrl(null);
              setUploading(false);
            }}
            uploading={uploading}
            isUpdateLoading={isUpdateLoading}
          />
        )}
      </div>
    );
};
