import React, { useEffect, useMemo } from "react";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { handleLoggedInEffect, useAuthSlice } from "../../redux/features";
import { useAppDispatch } from "../../redux";
import { useGetCatalogueQuery, useGetCompaniesQuery } from "../../redux/api";
import { Box, Paper, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { IRequisiteProps } from "../../interface";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

export const DashboardPage = () => {
  const { loggedIn } = useAuthSlice();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: catalogueData } = useGetCatalogueQuery();

  const { data: companiesData } = useGetCompaniesQuery();
  const { width, height } = useWindowSize();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(handleLoggedInEffect(false));
    }, 5000);
    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, [dispatch]);

  const companyItems = useMemo(
    () =>
      companiesData?.data.map(({ image, label, _id }, index) => (
        <Grid
          xs={2}
          sm={4}
          md={4}
          lg={1}
          xl={1}
          key={index}
          position="relative"
          onClick={() => navigate(`/cars/list/${_id}`)}
        >
          <Item sx={{ cursor: "pointer", userSelect: "none" }}>
            <img src={image} alt={label} />
            <Box bgcolor="#fff" px={1} borderRadius={0.8}>
              <Typography textAlign="right" variant="body2">
                {
                  catalogueData?.data.filter(
                    (prop) => (prop.brand as IRequisiteProps)._id === _id
                  ).length
                }
              </Typography>
            </Box>
          </Item>
        </Grid>
      )) ?? [],
    [companiesData, catalogueData?.data, navigate]
  );

  return (
    <div>
      {loggedIn && <Confetti height={height} width={width} />}
      <Box>
        <Typography variant="h4" mb={5}>
          Car Brands - {companiesData?.data.length ?? 0}
        </Typography>
        <Grid container gap={3}>
          {companyItems}
        </Grid>
      </Box>
    </div>
  );
};
