import { ColumnDef } from "@tanstack/react-table";
import { IColorsProps } from "../../../interface";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { AppTable } from "../../../component";
import { FC } from "react";

export interface ColorTableTableProps {
  data: IColorsProps[];
  isLoading: boolean;
  onDelete: (prop: IColorsProps) => void;
}

export const ColorTable: FC<ColorTableTableProps> = ({
  data,
  isLoading,
  onDelete,
}) => {
  const columns: ColumnDef<IColorsProps>[] = [
    {
      id: "name",
      accessorKey: "name",
      header: "Color Name",
      cell: ({ row }) => {
        return (
          <Typography textTransform="capitalize" variant="h5" fontWeight="500">
            {row.original.name}
          </Typography>
        );
      },
    },
    {
      id: "hex",
      accessorKey: "label",
      header: "Colors",
      cell: ({ row }) => {
        return (
          <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
            <Box
              height={20}
              border={0.3}
              borderColor="gray"
              width={50}
              bgcolor={row.original.hex}
            />
            <Typography textTransform="capitalize">
              {row.original.hex}
            </Typography>
          </Box>
        );
      },
    },

    {
      id: "action",
      accessorKey: "desc",
      header: "Actions",
      meta: {
        align: "right",
      },
      cell: ({ row }) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            gap={3}
          >
            <IconButton>
              <EditTwoTone />
            </IconButton>
            <IconButton
              onClick={() => {
                onDelete(row.original);
              }}
            >
              {isLoading ? <CircularProgress /> : <DeleteTwoTone />}
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Box mt={5} sx={{ width: "100vw", overflow: "auto" }}>
      <AppTable columns={columns} data={data} />
    </Box>
  );
};
