import React, { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { AppTable, PageTitle } from "../../../component";
import { IPackagesProps } from "../../../interface";
import { ColumnDef } from "@tanstack/react-table";
import {
  useDeletePackageMutation,
  useGetAllPackagesQuery,
} from "../../../redux/api";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const PackageListPage = () => {
  const { data, isError, error, isLoading, isFetching, isSuccess } =
    useGetAllPackagesQuery();
  const [DeletePackage] = useDeletePackageMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      console.log(error);
    }
  }, [isError, error]);

  const columns: ColumnDef<IPackagesProps>[] = [
    {
      id: "_id",
      header: "Sr. No",
      meta: {
        width: 100,
      },
      accessorKey: "_id",
      cell: ({ row }) => {
        return <Typography>{row.index + 1}</Typography>;
      },
    },
    {
      id: "name",
      accessorKey: "name",
      header: "Package Name",
      cell: ({ row }) => {
        return (
          <Typography textTransform="capitalize" fontWeight="500">
            {row.original.packageName} ({row.original.features?.length})
          </Typography>
        );
      },
    },
    {
      id: "price",
      accessorKey: "Price",
      meta: {
        align: "right",
      },
      cell: ({ row }) => {
        return (
          <Box>
            <Typography>
              {row.original.price.toLocaleString("en-IN", {
                currency: "INR",
                style: "currency",
                notation: "standard",
              })}
            </Typography>
            <Typography textTransform="capitalize">
              {row.original.duration.replace(/_/g, " ")}
            </Typography>
          </Box>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      meta: {
        align: "right",
        width: 200,
      },
      cell: ({ row }) => {
        return (
          <Box>
            <Typography color={row.original.status ? "green" : "red"}>
              {row.original.status ? "Active" : "In Active"}
            </Typography>
          </Box>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Actions",
      meta: {
        align: "right",
      },
      cell: ({ row }) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
          >
            <IconButton>
              <EditTwoTone />
            </IconButton>
            <IconButton
              onClick={async () =>
                await DeletePackage(row.original._id as string)
              }
            >
              <DeleteTwoTone />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Stack spacing={3}>
      <PageTitle
        addBtn={{
          action: () => navigate("/packages/new"),
          label: "Add Packages",
        }}
        title="Make Packages For Dealers"
        subTitle="Manage dealer packages from here"
        breadcrumbs={[
          { path: "/", title: "Home", active: false },
          { path: "#", title: "Master", active: false },
          { path: "/packages/list", title: "Packages", active: true },
        ]}
      />
      {data?.data.length !== 0 && isSuccess && !isFetching && !isLoading && (
          <AppTable columns={columns} data={data.data} />
      )}
      {data?.data.length === 0 && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          gap={3}
        >
          <Typography variant="h6" color="GrayText" fontWeight="500">
            Hey Admin click to create new package plan for your dealers
          </Typography>
          <Button
            variant="contained"
            color="inherit"
            size="large"
            disableElevation
            onClick={() => navigate("/packages/new")}
          >
            Create new
          </Button>
        </Box>
      )}
      {isFetching && isLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={100} />
        </Box>
      )}
    </Stack>
  );
};
