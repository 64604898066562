import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useCatalogueSlice } from "../../redux/features";
import { ChevronLeftTwoTone } from "@mui/icons-material";
import { IRequisiteProps } from "../../interface";
import Grid2 from "@mui/material/Unstable_Grid2";

export const CarDetailsPage = () => {
  const { id } = useParams();
  const { selectedCar } = useCatalogueSlice();
  const navigate = useNavigate();
  const [modal, setModal] = useState<boolean>(false);

  useEffect(() => {
    if (!id?.length || !selectedCar?._id) {
      return navigate(-1);
    }
  }, [id, selectedCar, navigate]);
  return (
    <Box>
      <Box display="flex" flexDirection="row" gap={3} alignItems="center">
        <IconButton
          size="large"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ChevronLeftTwoTone />
        </IconButton>
        <Typography textTransform="capitalize" variant="h4" fontWeight="500">
          {(selectedCar?.brand as IRequisiteProps)?.label}{" "}
          {(selectedCar?.carModel as IRequisiteProps)?.label}
        </Typography>
      </Box>
      <Box
        display="flex"
        mt={3}
        flexDirection="row"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        <Box width="40%" pr={3}>
          <img width="100%" src={selectedCar?.thumbnail} alt="" />
        </Box>
        <Box>
          <Button
            sx={{ borderRadius: 0 }}
            variant="outlined"
            onClick={() => setModal(true)}
          >
            Variants
          </Button>
        </Box>
      </Box>
      <Stack my={5} spacing={1}>
        <Box display="flex" flexDirection="row" gap={6}>
          <Box width="20%">
            <Typography variant="h6" fontWeight="500">
              Vehicle Sub Type
            </Typography>
          </Box>
          <Typography textTransform="capitalize">
            {(selectedCar?.vehicleSubType as IRequisiteProps)?.label}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={6}>
          <Box width="20%">
            <Typography variant="h6" fontWeight="500">
              Model
            </Typography>
          </Box>
          <Typography textTransform="capitalize">
            {(selectedCar?.carModel as IRequisiteProps)?.label}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={6}>
          <Box width="20%">
            <Typography variant="h6" fontWeight="500">
              Generation
            </Typography>
          </Box>
          <Typography textTransform="capitalize">
            {selectedCar?.generation}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={6}>
          <Box width="20%">
            <Typography variant="h6" fontWeight="500">
              Launch Year
            </Typography>
          </Box>
          <Typography textTransform="capitalize">
            {selectedCar?.launchYear}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={6}>
          <Box width="20%">
            <Typography variant="h6" fontWeight="500">
              Discontinued Year
            </Typography>
          </Box>
          <Typography textTransform="capitalize">
            {selectedCar?.discontinuedYear}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={6}>
          <Box width="20%">
            <Typography variant="h6" fontWeight="500">
              Body Length
            </Typography>
          </Box>
          <Typography textTransform="capitalize">
            {selectedCar?.bodyLength}mm
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={6}>
          <Box width="20%">
            <Typography variant="h6" fontWeight="500">
              Body Height
            </Typography>
          </Box>
          <Typography textTransform="capitalize">
            {selectedCar?.bodyHeight}mm
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={6}>
          <Box width="20%">
            <Typography variant="h6" fontWeight="500">
              Body Width
            </Typography>
          </Box>
          <Typography textTransform="capitalize">
            {selectedCar?.bodyWidth}mm
          </Typography>
        </Box>
        {selectedCar?.carExterior.length !== 0 && (
          <Box>
            <Typography variant="h5" fontWeight="500">
              Car Exterior
            </Typography>
            <Grid2 container spacing={3}>
              {selectedCar?.carExterior.map((image, i) => (
                <Grid2 key={i} xl={4} lg={4} md={6} sm={12} xs={12}>
                  <img src={image} alt={image} />
                </Grid2>
              ))}
            </Grid2>
          </Box>
        )}
        {selectedCar?.carInterior.length !== 0 && (
          <Box>
            <Typography variant="h5" fontWeight="500">
              Car Interior
            </Typography>
            <Grid2 container spacing={3}>
              {selectedCar?.carInterior.map((image, i) => (
                <Grid2 key={i} xl={4} lg={4} md={6} sm={12} xs={12}>
                  <img src={image} alt={image} />
                </Grid2>
              ))}
            </Grid2>
          </Box>
        )}
        {selectedCar?.carTyres.length !== 0 && (
          <Box>
            <Typography variant="h5" fontWeight="500">
              Car Tyres
            </Typography>
            <Grid2 container spacing={3}>
              {selectedCar?.carTyres.map((image, i) => (
                <Grid2 key={i} xl={4} lg={4} md={6} sm={12} xs={12}>
                  <img src={image} alt={image} />
                </Grid2>
              ))}
            </Grid2>
          </Box>
        )}
        <Box>
          <Typography variant="h6" fontWeight="500">
            Specification
          </Typography>
          <p
            dangerouslySetInnerHTML={{
              __html: selectedCar?.specification as string,
            }}
          />
        </Box>
      </Stack>
      <Dialog fullWidth open={modal} onClose={() => setModal(false)}>
        <DialogTitle>
          <Typography textTransform="capitalize" variant="h5" fontWeight="500">
            {(selectedCar?.brand as IRequisiteProps)?.label}{" "}
            {(selectedCar?.carModel as IRequisiteProps)?.label}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {selectedCar?.variants.map(
            ({ variant, fuelType, price, transmission, _id }) => (
              <Box key={_id} py={1}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Typography variant="h6">
                    {(variant as IRequisiteProps).label}
                  </Typography>
                  <Typography variant="body2">INR {price}</Typography>
                </Box>
                <Typography textTransform="uppercase" color="GrayText">
                  {(fuelType as IRequisiteProps).label} | {transmission}
                </Typography>
              </Box>
            )
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
