import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PageTitle } from "../../../component";
import { CircularProgress } from "@mui/material";
import {
  useCreateFuelTypesMutation,
  useDeleteFuelTypesMutation,
  useGetFuelTypesQuery,
} from "../../../redux/api";
import { IRequisiteProps } from "../../../interface";
import { useAppDispatch } from "../../../redux";
import { handleToast } from "../../../redux/features";
import { deleteImage, uploadImage } from "../../../utils";
import { FuelTypeTable } from "./table.fuel-type";
import { FuelTypeModelComponent } from "./new.fuel-type";

export const FuelTypeListPage = () => {
  const { data, isError, error, isLoading } = useGetFuelTypesQuery();
  const [
    NewFuelType,
    {
      isError: isNewError,
      error: newError,
      data: newData,
      isLoading: isNewLoading,
      isSuccess: isNewSuccess,
    },
  ] = useCreateFuelTypesMutation();
  const [
    DeleteFuelType,
    {
      isError: isDeleteError,
      error: deleteError,
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteFuelTypesMutation();

  const [onModel, setOnModel] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string | null>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isError) {
      console.log(error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (isNewError) {
      console.log(newError);
    }
    if (isNewSuccess) {
      dispatch(handleToast({ show: true, message: newData?.data }));
    }
  }, [isNewError, newError, isNewSuccess, newData?.data, dispatch]);

  useEffect(() => {
    if (isDeleteError) {
      console.log(deleteError);
    }
    if (isDeleteSuccess) {
      dispatch(handleToast({ show: true, message: deleteData?.data }));
    }
  }, [isDeleteError, deleteError, isDeleteSuccess, deleteData?.data, dispatch]);

  useEffect(() => {
    setUploading(true);

    if (file) {
      setUploading(true);
      (async () => {
        setUploading(false);
        const fileLink = await uploadImage(file);
        setFileUrl(fileLink as string);
      })();
    } else {
      dispatch(handleToast({ show: true, message: "please select image" }));
    }
    if (fileUrl) {
      setUploading(true);
    }
  }, [file, dispatch, fileUrl]);

  useEffect(() => {
    if (isNewSuccess) {
      setOnModel(false);
      setFile(null);
      setUploading(false);
      setFileUrl(null);
    }
  }, [isNewSuccess, newData?.data]);

  const onSubmit = async ({ label, desc }: IRequisiteProps) => {
    if (fileUrl?.length) {
      await NewFuelType({ label, desc, image: fileUrl });
    } else {
      dispatch(handleToast({ show: true, message: "please select image" }));
    }
  };

  const onDelete = async ({ _id, image }: IRequisiteProps) => {
    await deleteImage(image as string);
    await DeleteFuelType(_id as string);
  };

  if (isLoading) {
    return (
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    );
  } else
    return (
      <div>
        <PageTitle
          addBtn={{
            action: () => setOnModel(true),
            label: "New Fuel Type",
          }}
          title="Make Fuel Type"
          subTitle="Manage fuel type from here"
          breadcrumbs={[
            { path: "/", title: "Home", active: false },
            { path: "#", title: "Master", active: false },
            { path: "/fuel-type", title: "fuel type", active: true },
          ]}
        />
        {data?.data && (
          <FuelTypeTable
            data={data.data}
            isLoading={isDeleteLoading}
            onDelete={onDelete}
          />
        )}
        {onModel && (
          <FuelTypeModelComponent
            fileUrl={fileUrl as string}
            isNewLoading={isNewLoading}
            onModel={onModel}
            onSubmit={onSubmit}
            setFile={setFile as Dispatch<SetStateAction<File>>}
            setOnModel={setOnModel}
            onClose={() => {
              setOnModel(false);
              setFile(null);
              setFileUrl(null);
              setUploading(false);
            }}
            uploading={uploading}
          />
        )}
      </div>
    );
};
