import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "..";
import { ICatalogueProps } from "../../interface";

export interface LayoutSliceProps {
  toast: {
    show: boolean;
    message?: string | null;
  };
  selectedCar: ICatalogueProps | null;
}

const initialState: LayoutSliceProps = {
  toast: {
    show: false,
  },
  selectedCar: null,
};

export const LayoutSlice = createSlice({
  initialState,
  name: "layout",
  reducers: {
    handleToast: (
      state,
      action: PayloadAction<{ message?: string; show: boolean }>
    ) => {
      state.toast = action.payload;
    },

    removeCar: (state) => {
      state.selectedCar = null;
    },
  },
});

export const LayoutReducer = LayoutSlice.reducer;
export const useLayoutSlice = () =>
  useAppSelector((state) => {
    return state.layout;
  });
export const { handleToast, removeCar } = LayoutSlice.actions;
