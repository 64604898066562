import { Box, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ICatalogueProps } from "../../interface";
import { useAppDispatch } from "../../redux";
import { setCarDetails } from "../../redux/features";

export interface ICarCardProps {
  image: string;
  cardModel: string;
  launchYear: string;
  price: string;
  brandName: string;
  brandImage: string;
  redirect: string;
  variantLength: number;
  props: ICatalogueProps;
}

export const CarCard: FC<ICarCardProps> = ({
  cardModel,
  image,
  launchYear,
  price,
  brandImage,
  brandName,
  redirect,
  variantLength,
  props,
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCardClick = () => {
    navigate(redirect);
    dispatch(setCarDetails(props));
  };

  return (
    <Box
      onClick={handleCardClick}
      display="flex"
      gap={5}
      alignItems="center"
      sx={{
        cursor: "pointer",
        "&:hover": {
          "& .hover-text": {
            color: palette.primary.main,
          },
          "& .hover-media": {
            transform: "scale(1.2)",
          },
        },
      }}
    >
      <Box
        sx={{
          width: "20%",
          height: "auto",
          overflow: "hidden",
        }}
      >
        <img
          src={image}
          alt={cardModel}
          className="hover-media"
          style={{
            width: "100%",
            height: "auto",
            display: "block",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Box>

      <Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <img src={brandImage} width={30} alt={brandName} />
          <Typography
            variant="body2"
            textTransform="capitalize"
            className="hover-text"
          >
            {brandName}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          textTransform="capitalize"
          className="hover-text"
          sx={{
            transition: "color 0.3s ease-in-out",
          }}
        >
          {launchYear} {cardModel}
        </Typography>
        <Typography variant="body1" color="GrayText">
          {price}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mt={1}
          gap={3}
        >
          <Typography>{variantLength} available</Typography>
        </Box>
      </Box>
    </Box>
  );
};
