import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { AppTable, PageTitle } from "../../component";
import { useNavigate } from "react-router-dom";
import {
  useGetDealersQuery,
  useUpdateDealerMutation,
} from "../../redux/api/dealer.api";
import { ColumnDef } from "@tanstack/react-table";
import { IDealerProps } from "../../interface";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { useAppDispatch } from "../../redux";
import { handleToast } from "../../redux/features";
import moment from "moment";
import { LoadingButton } from "@mui/lab";

export const DealerListPage = () => {
  const navigate = useNavigate();
  const { data, isError, error, isLoading } = useGetDealersQuery();
  const [
    UpdateDealer,
    {
      isError: isUpdateError,
      error: updateError,
      data: updateData,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateDealerMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isError) {
      const err = error as any;
      if (err.data) {
        dispatch(
          handleToast({
            show: true,
            message: err.data.message,
          })
        );
      } else {
        console.log(err);
      }
    }
  }, [isError, error, dispatch]);

  useEffect(() => {
    if (isUpdateError) {
      const err = updateError as any;
      if (err.data) {
        dispatch(
          handleToast({
            show: true,
            message: err.data.message,
          })
        );
      } else {
        console.log(err);
      }
    }
  }, [isUpdateError, updateError, dispatch]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(
        handleToast({
          show: true,
          message: "Dealer updated successfully",
        })
      );
    }
  }, [isUpdateSuccess, updateData, dispatch]);

  const blockDealer = async (id: string, current: boolean) => {
    await UpdateDealer({
      id: id,
      payload: { blocked: !current },
    });
  };

  const columns: ColumnDef<IDealerProps>[] = [
    {
      accessorKey: "_id",
      meta: {
        width: 50,
      },
      cell: ({ row }) => {
        return (
          <Typography variant="body2" fontWeight="500">
            {row.index + 1}
          </Typography>
        );
      },
    },
    {
      accessorKey: "_id",
      meta: {
        width: 150,
      },
      header: "License Key",
      cell: ({ row }) => {
        return (
          <Box>
            <Tooltip title={row.original._id} placement="bottom">
              <Typography color="GrayText">Hover to see</Typography>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      accessorKey: "name",
      header: "Dealer Name",
      cell: ({ row }) => {
        return (
          <Box>
            <Typography>{row.original.name}</Typography>
          </Box>
        );
      },
    },
    {
      accessorKey: "email",
      header: "Email address",
      cell: ({ row }) => {
        return (
          <Box>
            <Typography color="GrayText">{row.original.email}</Typography>
          </Box>
        );
      },
    },
    {
      accessorKey: "packageEnd",
      header: "Package Duration",
      cell: ({ row }) => {
        return (
          <Typography color="GrayText">
            {row.original.packageEnd
              ? moment(row.original.packageEnd).fromNow()
              : "No Packages found"}
          </Typography>
        );
      },
    },
    {
      accessorKey: "blocked",
      header: "Blocked",
      cell: ({ row }) => {
        return (
          <Box display="flex" gap={1} justifyContent="center">
            <LoadingButton
              type="button"
              loading={isUpdateLoading}
              variant="contained"
              color={row.original.blocked ? "error" : "success"}
              disableElevation
              size="small"
              onClick={() =>
                blockDealer(
                  row.original._id as string,
                  row.original.blocked as boolean
                )
              }
            >
              {row.original.blocked ? "Unblock" : "Block"}
            </LoadingButton>
          </Box>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      meta: {
        align: "right",
      },
      cell: ({ row }) => {
        return (
          <Box display="flex" gap={1} justifyContent="end">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              startIcon={<EditTwoTone />}
              size="small"
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="error"
              disableElevation
              startIcon={<DeleteTwoTone />}
              size="small"
            >
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box>
      <PageTitle
        addBtn={{
          action: () => navigate("/dealer/new"),
          label: "Dealer",
        }}
        title="Make For Dealers"
        subTitle="Manage dealer packages from here"
        breadcrumbs={[
          { path: "/", title: "Home", active: false },
          { path: "#", title: "Master", active: false },
          { path: "/dealer/list", title: "Dealer", active: true },
        ]}
      />
      {isLoading && <CircularProgress size="large" />}
      {!isLoading && <AppTable data={data?.data || []} columns={columns} />}
    </Box>
  );
};
