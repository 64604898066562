import { Route, RouteProps, Routes } from "react-router-dom";
import { AppProvider } from "./provider";
import { AppLayout, DefaultLayout } from "./layout/";
import {
  CarModelListPage,
  NewCataloguePage,
  ColorsListPage,
  CompanyListPage,
  DashboardPage,
  FuelTypeListPage,
  LoginPage,
  NewPackagesPage,
  NotFoundPage,
  PackageListPage,
  VariantListPage,
  VehicleSubTypeListPage,
  VehicleTypeListPage,
  WheelListPage,
  CarDetailsPage,
  CarListPage,
  DealerListPage,
  NewDealerPage,
} from "./pages";
import { ProtectedRoute } from "./utils";

export default function App() {
  const appPages: RouteProps[] = [
    {
      path: "/",
      Component: DashboardPage,
    },
    {
      path: "/wheels",
      Component: WheelListPage,
    },
    {
      path: "/vehicle-type",
      Component: VehicleTypeListPage,
    },
    {
      path: "/vehicle-sub-type",
      Component: VehicleSubTypeListPage,
    },
    {
      path: "/company",
      Component: CompanyListPage,
    },
    {
      path: "/car-models",
      Component: CarModelListPage,
    },
    {
      path: "/car-details/:id",
      Component: CarDetailsPage,
    },
    {
      path: "/fuel-type",
      Component: FuelTypeListPage,
    },
    {
      path: "/new-catalogue",
      Component: NewCataloguePage,
    },
    {
      path: "/variant",
      Component: VariantListPage,
    },
    {
      path: "/colors",
      Component: ColorsListPage,
    },
    {
      path: "/packages/list",
      Component: PackageListPage,
    },
    {
      path: "/packages/new",
      Component: NewPackagesPage,
    },
    {
      path: "/cars/list/:brandId",
      Component: CarListPage,
    },
    {
      path: "/dealer/list",
      Component: DealerListPage,
    },
    {
      path: "/dealer/new",
      Component: NewDealerPage,
    },
  ];
  return (
    <AppProvider>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/sign-in" Component={LoginPage} />
          <Route path="*" Component={NotFoundPage} />
        </Route>
        <Route element={<DefaultLayout />}>
          <Route element={<ProtectedRoute />}>
            {appPages.map((props) => (
              <Route key={props.path} {...props} />
            ))}
          </Route>
        </Route>
      </Routes>
    </AppProvider>
  );
}
