import { FC } from "react";

import {
  CardGiftcardTwoTone,
  ChevronLeftTwoTone,
  ChevronRightTwoTone,
  DirectionsCarTwoTone,
  Inventory2TwoTone,
  SpeedTwoTone,
  VerifiedUserTwoTone,
} from "@mui/icons-material";
import {
  Collapse,
  CSSObject,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
  useTheme,
} from "@mui/material";
import { MenuLink, MenuLinkProps } from "../../component";
import MuiDrawer from "@mui/material/Drawer";

export interface SideBarProps {
  width: number;
  open: boolean;
  handleDrawerClose: () => void;
  handleClick: () => void;
  collapse: boolean;
  MakeModels: MenuLinkProps[];
}

export const SideBar: FC<SideBarProps> = ({
  width,
  open,
  handleDrawerClose,
  handleClick,
  collapse,
  MakeModels,
}) => {
  const { palette, direction } = useTheme();
  const openedMixin = (theme: Theme): CSSObject => ({
    width: width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: width,
    flexShrink: 0,
    whiteSpace: "nowrap",
    borderRight: 0,
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": {
        ...openedMixin(theme),
        borderRight: `0.5px solid ${theme.palette.grey[300]}`,
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": {
        ...closedMixin(theme),
        borderRight: `0.5px solid ${theme.palette.grey[300]}`,
      },
    }),
  }));

  return (
    <Drawer variant="permanent" open={open} elevation={0}>
      <DrawerHeader sx={{ border: "none" }}>
        <IconButton onClick={handleDrawerClose}>
          {direction === "rtl" ? (
            <ChevronRightTwoTone />
          ) : (
            <ChevronLeftTwoTone />
          )}
        </IconButton>
      </DrawerHeader>
      <List
        sx={{
          paddingRight: 1,
          paddingLeft: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 2,
        }}
      >
        <MenuLink Icon={SpeedTwoTone} label="Dashboard" open={open} path="/" />
        <div onClick={() => handleClick()}>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              color: palette.grey[600],
              borderRadius: 1,
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                display: "flex",
                alignItems: "center",
                color: palette.grey[600],
                borderRadius: 1,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : "auto",
                  justifyContent: "center",
                }}
              >
                <CardGiftcardTwoTone />
              </ListItemIcon>
              <ListItemText
                sx={{
                  opacity: open ? 1 : 0,
                  fontSize: "0.875rem",
                  textTransform: "capitalize",
                  fontSizeAdjust: 30,
                }}
              >
                Make & Models
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </div>
        <Collapse in={collapse} timeout="auto" appear unmountOnExit>
          <List component="div">
            {MakeModels.map(({ label, open, path, Icon }) => (
              <MenuLink
                key={label}
                label={`- ${label}`}
                open={open}
                path={path}
                Icon={Icon}
              />
            ))}
          </List>
        </Collapse>
        <MenuLink
          Icon={DirectionsCarTwoTone}
          label="New Catalogue"
          open={open}
          path="/new-catalogue"
        />
        <MenuLink
          Icon={Inventory2TwoTone}
          label="Packages"
          open={open}
          path="/packages/list"
        />
        <MenuLink
          Icon={VerifiedUserTwoTone}
          label="Dealers"
          open={open}
          path="/dealer/list"
        />
      </List>
    </Drawer>
  );
};
