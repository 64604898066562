import React, { FC, ReactNode } from "react";
import { ThemeProvider } from "../theme";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "../redux";
import { PersistGate } from "redux-persist/integration/react";

export interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider>
          <BrowserRouter>{children}</BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  );
};
