import React, { useEffect } from "react";

import { Formik } from "formik";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ISignInProps } from "../../../interface";
import { Link, useNavigate } from "react-router-dom";
import { VisibilityOffTwoTone, VisibilityTwoTone } from "@mui/icons-material/";
import { SignInRule } from "../../../validation";
import { useAppDispatch } from "../../../redux";
import {
  handleShowPassword,
  handleToast,
  saveToken,
  useAuthSlice,
} from "../../../redux/features";
import { useSignInMutation } from "../../../redux/api/auth.api";

export const LoginPage = () => {
  const { palette } = useTheme();
  const { showPassword, token } = useAuthSlice();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [SignIn, { isError, error, data, isLoading, isSuccess }] =
    useSignInMutation();

  useEffect(() => {
    if (isError) {
      const err = error as any;
      if (err?.data) {
        dispatch(handleToast({ show: true, message: err.data.message }));
      } else {
        dispatch(handleToast({ show: true, message: err.message }));
      }
    }
  }, [isError, error, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(handleToast({ show: true, message: "Sign in successful..." }));
      dispatch(saveToken(data?.data.token as string));
      navigate("/", { replace: true });
    }
  }, [isSuccess, data?.data, dispatch, navigate]);

  const handleSubmit = async ({ email, password }: ISignInProps) => {
    await SignIn({ email, password });
  };

  useEffect(() => {
    if (token) {
      navigate("/", { replace: true });
    }
  }, [token, navigate]);

  return (
    <Box
      sx={{
        maxHeight: "100vh",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box width="35%">
        <Box
          bgcolor="#FAF9FA"
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "#FAF9FA",
            gap: 20,
          }}
        >
          <Typography
            variant="h3"
            fontFamily="Barlow, sans-serif"
            textAlign="center"
          >
            Hi, Welcome back
          </Typography>
          <Typography variant="body1" color="GrayText" textAlign="center">
            More effectively with optimized workflows.
          </Typography>
          <Box
            component="img"
            src="https://minimals.cc/assets/illustrations/illustration-dashboard.webp"
            width="80%"
            mx="auto"
          ></Box>
        </Box>
      </Box>
      <Box
        p={3}
        flex={1}
        bgcolor="#fff"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box mx="auto" width="50%">
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={handleSubmit}
            validationSchema={SignInRule}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    <Typography variant="h5">
                      Sign in to your account
                    </Typography>
                    <Typography color="GrayText">
                      Don’t have an account?{" "}
                      <Link
                        to="/sign-up"
                        style={{ color: palette.primary.main }}
                      >
                        Get Started
                      </Link>
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    FormHelperTextProps={{
                      sx: {
                        textAlign: "right",
                      },
                    }}
                    type="text"
                    variant="outlined"
                    placeholder="Enter Email address"
                    defaultValue={values.email}
                    onChange={handleChange("email")}
                    onBlur={handleBlur("email")}
                    error={touched.email}
                    helperText={errors.email}
                    label="Email address"
                    color="primary"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      flexDirection: "column",
                    }}
                  >
                    <Link
                      to="/forgot-password"
                      style={{
                        color: "#000",
                        textDecoration: "none",
                        marginBottom: 10,
                      }}
                    >
                      Forgot Password?
                    </Link>
                    <TextField
                      FormHelperTextProps={{
                        sx: {
                          textAlign: "right",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                dispatch(handleShowPassword(!showPassword))
                              }
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffTwoTone />
                              ) : (
                                <VisibilityTwoTone />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      label="Password"
                      color="primary"
                      defaultValue={values.password}
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      error={touched.password}
                      helperText={errors.password}
                    />
                  </Box>
                  <LoadingButton
                    loading={isLoading}
                    sx={{ backgroundColor: palette.grey[900] }}
                    size="large"
                    color="inherit"
                    variant="contained"
                    type="submit"
                  >
                    Sign In
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};
