import { createApi } from "@reduxjs/toolkit/query/react";
import { useServer } from "../../utils";
import { ISignInProps, IUserProps } from "../../interface";

const AuthApi = createApi({
  baseQuery: useServer,
  reducerPath: "authApi",
  endpoints: ({ mutation, query }) => ({
    SignIn: mutation<{ data: { token: string } }, ISignInProps>({
      query: ({ ...payload }) => {
        return {
          url: "/user/auth/sign-in",
          method: "POST",
          body: {
            ...payload,
          },
        };
      },
    }),
    SignUp: mutation<{ data: string }, IUserProps>({
      query: ({ ...payload }) => {
        return {
          url: "/user/auth/sign-up",
          method: "POST",
          body: {
            ...payload,
          },
        };
      },
    }),
    SignProfile: query<{ data: IUserProps }, void>({
      query: () => "/user/auth/profile",
    }),
  }),
});

export const AuthApiReducer = AuthApi.reducer;
export const AuthApiMiddleware = AuthApi.middleware;
export const {
  useSignInMutation,
  useLazySignProfileQuery,
  useSignProfileQuery,
  useSignUpMutation,
} = AuthApi;
