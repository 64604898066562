import { IconButton, Snackbar } from "@mui/material";
import React, { FC, Fragment } from "react";
import { Outlet } from "react-router-dom";
import { handleToast, useLayoutSlice } from "../../redux/features";
import { useAppDispatch } from "../../redux";
import { CloseTwoTone } from "@mui/icons-material";

export interface AppLayoutProps {}

export const AppLayout: FC<AppLayoutProps> = () => {
  const { toast } = useLayoutSlice();
  const dispatch = useAppDispatch();
  return (
    <Fragment>
      <Snackbar
        open={toast.show}
        message={toast.message}
        autoHideDuration={3000}
        onClose={() => dispatch(handleToast({ show: false }))}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        action={
          <IconButton onClick={() => dispatch(handleToast({ show: false }))}>
            <CloseTwoTone />
          </IconButton>
        }
      />
      <Outlet />
    </Fragment>
  );
};
